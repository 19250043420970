import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './AuthForm.module.scss';
import Spinner from '../Spinner/Spinner';
import { AUTH_STATUS, AUTH_TYPE, DB_LOAD_STATUS } from '../../config';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as PasswordIcon } from '../../assets/icons/password.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';

const AuthForm = (props) => {
	const {
		authStatus,
		authType,
		formData,
		colors,
		dbLoadStatus,
		isSending,
		authErrorMessage,
		dataUpdated,
		changeAuthType,
		changeFormData,
		changeColor,
		changeMembers,
		addMember,
		deleteMember,
		changeFormDataValidity,
		submitFormData,
		deleteTeam,
		closePageModal,
		t
	} = props;

	const addMemberKeyDownHandler = e => {
		if(e.keyCode === 32) {
			e.preventDefault();
			addMember();
		}
	}
	const colorInputKeyDownHandler = (color, e) => {
		if(e.keyCode === 32) {
			e.preventDefault();
			changeColor(color);
		}
	}
	const linkKeyDownHandler = (authType, e) => {
		if(e.keyCode === 32) {
			e.preventDefault();
			changeAuthType(authType);
		}
	}

	const isAuthenticating = (authStatus === AUTH_STATUS.AUTHENTICATING);
	const isSignup = (authType === AUTH_TYPE.SIGNUP);
	const isLogin = (authType === AUTH_TYPE.LOGIN);
	const isReset = (authType === AUTH_TYPE.RESET);
	const isEdit = (authType === AUTH_TYPE.EDIT);
	const isDelete = (authType === AUTH_TYPE.DELETE);

	let authForm;
	if(((isSignup || isEdit)) && dbLoadStatus === DB_LOAD_STATUS.LOADING) {
		authForm = (
			<div className={classes.authFormBlank}>
				<Spinner />
			</div>
		);
	}
	else {
		/* HEADER */
		const headerText = (isSignup) ? t('auth.signupHeader')
			: (isLogin) ? t('auth.loginHeader')
			: (isEdit) ? t('auth.editHeader')
			: (isDelete) ? t('auth.deleteHeader')
			: t('auth.resetHeader');

		const header = (
			<React.Fragment>
				<h1 className={classes.header}>{headerText}</h1>
				{isReset && (
					<p className={classes.resetText}>{t('auth.resetText')}</p>
				)}
				{authErrorMessage && (
					<p className={classes.authErrorMessage}>{authErrorMessage}</p>
				)}
			</React.Fragment>
		);

		/* FORM */
		const submitButtonText = (isAuthenticating || isSending) ? null
			: (isSignup) ? t('auth.signupButton')
			: (isLogin) ?	t('auth.loginButton')
			: (isEdit) ?	t('auth.editButton')
			: t('auth.resetButton');

		const form = (
			<form onSubmit={submitFormData}> 
				<div className={classes.formFields}>
					{!isDelete &&  (
						<div className={`field ${classes.field}`}>
							<div className='control has-icons-left'>
								<input 
									className={`input ${classes.input} ${formData.email.valid ? '' : classes.invalid}`}
									name='email' 
									type='text'
									placeholder={t('auth.email')}
									value={formData.email.value}
									onChange={changeFormData}
									onBlur={changeFormDataValidity}
								/>
								<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
					      	<EmailIcon className={classes.inputIcon}/>
					    	</span>
							</div>
							{!formData.email.valid && (
								<p className={classes.inputErrorMessage}>{formData.email.errorMessage}</p>
							)}
						</div>
					)}
					{(isSignup || isLogin) &&  (
						<div className={`field ${classes.field}`}>
							<div className='control has-icons-left'>
								<input 
									className={`input ${classes.input} ${formData.password.valid ? '' : classes.invalid}`}
									type='password'
									name='password'
									placeholder={t('auth.password')}
									value={formData.password.value}
									onChange={changeFormData}
									onBlur={changeFormDataValidity}
								/>
								<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
					      	<PasswordIcon className={classes.inputIcon}/>
					    	</span>							
							</div>
						{!formData.password.valid && (
							<p className={classes.inputErrorMessage}>{formData.password.errorMessage}</p>
						)}
						</div>
					)}
					{(isSignup || isEdit) && (
						<React.Fragment>
							<div className={`field ${classes.field}`}>
								<div className='control'>
									<input 
										className={`input ${classes.input} ${formData.name.valid ? '' : classes.invalid}`}
										name='name'
										type='text'
										placeholder={t('auth.name')}
										value={formData.name.value}
										onChange={changeFormData}
										onBlur={changeFormDataValidity}
									/>
								</div>
								{!formData.name.valid && (
									<p className={classes.inputErrorMessage}>{formData.name.errorMessage}</p>
								)}
							</div>
							<div className={`field ${classes.field}`}>
								<div className='control'>
									<input 
										className={`input ${classes.input} ${formData.phone.valid ? '' : classes.invalid}`}
										name='phone'
										type='text'
										placeholder={t('auth.phone')}
										value={formData.phone.value}
										onChange={changeFormData}
										onBlur={changeFormDataValidity}
									/>
								</div>
								{!formData.phone.valid && (
									<p className={classes.inputErrorMessage}>{formData.phone.errorMessage}</p>
								)}
							</div>
							<div className={`field ${classes.field} ${classes.membersField}`}>
								{formData.members.value.map(member => (
								<div className={`control`} key={member.id}>
										<input 
											className={`input ${classes.input} ${formData.members.valid ? '' : classes.invalid}`}
											name='members'
											placeholder={t('auth.members')}
											value={member.member}
											onChange={changeMembers.bind(this, member.id)}
											onBlur={changeFormDataValidity}
										/>
										{(formData.members.value.length > 1) && (
											<DeleteIcon className={classes.deleteIcon} onClick={deleteMember.bind(this, member.id)}/>
										)}
								</div>
								))}
								<div
									className={classes.addButton}
									tabIndex='0'
									onClick={addMember}
									onKeyDown={addMemberKeyDownHandler}
								>
									<AddIcon className={classes.addIcon} />
									<span className={classes.addText}>{t('auth.addText')}</span>
								</div>
							</div>
						</React.Fragment>
					)}
					{isSignup && (				
						<div className={`field ${classes.field}`}>
							<div className={classes.colorInput}>
								<span className={classes.colorInputLabel}>{t('auth.color')}</span>
								<div className={classes.colors}>
									{colors && colors.map(color => (
										<div
											key={color}
											className={`${classes.colorBox} ${(formData.color.value === color) ? classes.selected : ''}`}
											style={{ backgroundColor: color }}
											tabIndex='0'
											onClick={changeColor.bind(this, color)}
											onKeyDown={colorInputKeyDownHandler.bind(this, color)}>
										</div>
									))}
								</div>
							</div>
							{!formData.color.valid && (
								<p className={classes.inputErrorMessage}>{formData.color.errorMessage}</p>
							)}
						</div>
					)}
					{isDelete && (
						<React.Fragment>
							<p className={classes.deleteQuestion}>{t('auth.deleteQuestion')}</p>
							<div className={classes.deleteNote}>
								<p>{t('auth.deleteNote1')}</p>
								<p>{t('auth.deleteNote2')}</p>
							</div>
						</React.Fragment>
					)}
					{(isEdit || isDelete) && (
						<React.Fragment>
							<p className={classes.passwordText}>{isEdit ? t('auth.editPasswordText') : t('auth.deletePasswordText')}</p>
							<div className={`field ${classes.field}`}>
								<div className='control has-icons-left'>
									<input 
										className={`input ${classes.input} ${formData.password.valid ? '' : classes.invalid}`}
										type='password'
										name='password'
										placeholder={t('auth.password')}
										value={formData.password.value}
										onChange={changeFormData}
										onBlur={changeFormDataValidity}
									/>
									<span className={`icon is-small is-left ${classes.inputIconContainer}`}>
						      	<PasswordIcon className={classes.inputIcon}/>
						    	</span>							
								</div>
								{!formData.password.valid && (
									<p className={classes.inputErrorMessage}>{formData.password.errorMessage}</p>
								)}
							</div>
						</React.Fragment>
					)}
				</div>
				{isSignup && (
					<div className={classes.conditions}>
						<label className={`checkbox ${formData.accept.valid ? '' : classes.invalid}`}>
							<input
								name='accept'
								type='checkbox'
								checked={formData.accept.value}
								onChange={changeFormData}
							/>
							<span>
								{t('auth.accept')}
								<a href={process.env.PUBLIC_URL + 'politica-privacidad.html'} target="_blank" rel="noopener noreferrer">
									{t('auth.conditions')}
								</a>
							</span>
						</label>
						<p className={classes.inputErrorMessage}>
							{!formData.accept.valid ? formData.accept.errorMessage : null}
						</p>
					</div>
				)}
				{isDelete ? (
					<div className={classes.deleteButtonsContainer}>
						<button
							className={`button ${classes.deleteButton} ${classes.red} ${isSending ? 'is-loading' : ''}`}
							onClick={deleteTeam}
						>
							{isSending ? null : t('auth.deleteButton')}
						</button>
						<button 
							className={`button ${classes.deleteButton}`}
							onClick={closePageModal}
							disabled={isSending}
						>
							{t('auth.cancelButton')}
						</button>
					</div>
				) : (
					<button 
						className={`button ${classes.submitButton} ${(isAuthenticating || isSending) ? 'is-loading' : ''}`}
						onClick={submitFormData}
						disabled={isEdit && !dataUpdated}
					>
						{submitButtonText}
					</button>
				)}
			</form>
		);
		
		/* LINKS */
		const links = (
			<React.Fragment>
				{(isSignup || isLogin) && (
					<p
						className={classes.link}
						tabIndex='0'
						onClick={isLogin ? changeAuthType.bind(this, AUTH_TYPE.SIGNUP) : changeAuthType.bind(this, AUTH_TYPE.LOGIN)}
						onKeyDown={isLogin ? linkKeyDownHandler.bind(this, AUTH_TYPE.SIGNUP) : linkKeyDownHandler.bind(this, AUTH_TYPE.LOGIN)}
					>
						{isLogin ? t('auth.signupLink') : t('auth.loginLink')}
					</p>
				)}
				{isLogin && (
					<p
						className={classes.link}
						tabIndex='0'
						onClick={changeAuthType.bind(this, AUTH_TYPE.RESET)}
						onKeyDown={linkKeyDownHandler.bind(this, AUTH_TYPE.RESET)}
					>
						{t('auth.resetLink')}
					</p>
				)}
			</React.Fragment>
		);

		/* AUTH FORM */
		authForm = (
			<React.Fragment>
				{header}
				{form}
				{links}
			</React.Fragment>
		);
	}

	return authForm;
}

export default withTranslation()(AuthForm);