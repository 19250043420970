import React, { Component } from 'react';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN, INITIAL_MAP, NEW_INITIATIVE_DEFAULT_COLOR, CHANGE_MAP } from '../../config';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

class Map extends Component {

  map;
  mapContainer = React.createRef();
  markers = {};
  newInitiativeMarker = null;

  constructor(props) {
    super(props);
    this.state = {
      markers: {},
      newInitiativeMarker: null
    }
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      ...INITIAL_MAP
    });
    this.map.addControl(new mapboxgl.AttributionControl({ customAttribution: ['Developed by <a href="https://cartometrics.com" target="_blank"><strong>Cartometrics</strong></a>'] }), 'bottom-right');
    this.map.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'top-right');
  }

  componentDidUpdate(prevProps) {
    if(this.props.changeMapTrigger !== prevProps.changeMapTrigger) {
      this.changeMapTriggerHandler();
    }

    if(this.props.selectedInitiativeId !== prevProps.selectedInitiativeId &&
      this.props.changeMapTrigger.action !== CHANGE_MAP.DELETE_INITIATIVE) {
        this.selectInitiativeOnMap(this.props.selectedInitiativeId, prevProps.selectedInitiativeId);
    }

    if(this.props.newInitiativeButtonIsSelected !== prevProps.newInitiativeButtonIsSelected) {
      if(this.props.newInitiativeButtonIsSelected) {
        const { teamColor } = this.props;
        // Need to encode '#' in hex color code as '%23' as it is a reserved character in a URL
        const color = teamColor ? teamColor.replace('#', '%23') : NEW_INITIATIVE_DEFAULT_COLOR;
        this.map.getCanvas().style.cursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="${color}" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>') 17 33, auto`;
        this.map.on('click', this.props.pinNewInitiative);
      } else {
        this.map.getCanvas().style.cursor = 'inherit';
        this.map.off('click', this.props.pinNewInitiative);
      }
    }
  }

  changeMapTriggerHandler() {
    switch(this.props.changeMapTrigger.action) {
      case CHANGE_MAP.PIN_INITIATIVE:
        this.pinNewInitiativeOnMap();
        break;
      case CHANGE_MAP.CANCEL_INITIATIVE:
        this.cancelNewInitiative();
        break;
      case CHANGE_MAP.LOAD_INITIATIVES:
        this.loadInitiativesOnMap();
        break;
      case CHANGE_MAP.ADD_INITIATIVE:
        this.addInitiativeOnMap();
        break;
      case CHANGE_MAP.COMPLETE_INITIATIVE:
        this.completeInitiativeonMap();
        break;     
      case CHANGE_MAP.DELETE_INITIATIVE:
        this.deleteInitiativeFromMap();
        break;
      case CHANGE_MAP.DELETE_INITIATIVES:
        this.deleteInitiativesFromMap();
        break;
      case CHANGE_MAP.UPDATE_FILTERS:
        this.updateFiltersOnMap();
        break;
      default:
        break;
    }    
  }

  pinNewInitiativeOnMap() {
    const { teamColor } = this.props;
    const el = document.createElement('div');
    const color = teamColor ? teamColor.replace('#', '%23') : NEW_INITIATIVE_DEFAULT_COLOR;
    el.style.backgroundImage = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="${color}" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>')`;
    el.style.width = '24px';
    el.style.height = '24px';     
    const newInitiativeMarker = new mapboxgl.Marker(el, { anchor: 'bottom' })
      .setLngLat(this.props.newInitiativeCoordinates)
      .addTo(this.map);
    this.setState({ newInitiativeMarker });
  }

  cancelNewInitiative() {
    if(this.state.newInitiativeMarker) {
      this.state.newInitiativeMarker.remove();
      this.setState({ newInitiativeMarker: null });
    }
  }

  inititiaveClickEventHandler = e => {
    const initiativeId = e.target.getAttribute('data-id');
    if(initiativeId === this.props.selectedInitiativeId) {
      this.shiftMapToCenterInitiative(this.props.initiatives[initiativeId].coordinates);  
    } else {
      this.props.selectInitiative(initiativeId);
    }
  }

  shiftMapToCenterInitiative(initiativeCoords, centerPixelCoords) {
    const initiativePixelCoords = this.map.project(initiativeCoords);
    const centeredPixelCoords = (window.innerWidth > 650) ? [650, 270]
      : (window.innerHeight > window.innerWidth) ? [140, 400] : [350, window.innerHeight / 2];
    const pixelCoordsTranslate = [initiativePixelCoords.x - centeredPixelCoords[0], initiativePixelCoords.y - centeredPixelCoords[1]];
    const currentCenterPixelCoords = this.map.project(this.map.getCenter());
    const newCenterPixelCoords = [currentCenterPixelCoords.x + pixelCoordsTranslate[0], currentCenterPixelCoords.y + pixelCoordsTranslate[1]];
    this.map.easeTo({ center: this.map.unproject(newCenterPixelCoords), duration: 500 });    
  }

  createInitiativeMarker(initiativeId, initiativeData) {
    const el = document.createElement('div');
    el.style.width = '15px';
    el.style.height = '15px';
    el.style.borderRadius = '50%';
    el.style.backgroundColor = initiativeData.teamColor;
    el.style.cursor = 'pointer';
    el.style.transition = 'height .2s ease-out, width .2s ease-out, box-shadow .2s ease-out';
    if(initiativeData.completionDate) {
      el.style.boxShadow = '0px 0px 15px 8px #ffeab0';
    } else {
      el.style.opacity = '.6';
    }
    el.setAttribute('data-id', initiativeId);
    el.addEventListener('click', this.inititiaveClickEventHandler);
    const marker = new mapboxgl.Marker(el, { anchor: 'center' })
      .setLngLat(initiativeData.coordinates)
      .addTo(this.map);
    return marker;
  }

  loadInitiativesOnMap() {
    const markers = {};
    const { initiatives } = this.props;
    for(let id in initiatives) {
      markers[id] = this.createInitiativeMarker(id, initiatives[id]);
    }
    this.setState({ markers }, this.props.onFinishMapLoading);
  }

  addInitiativeOnMap() {
    const id = this.props.changeMapTrigger.id;
    if(this.state.newInitiativeMarker) this.state.newInitiativeMarker.remove();
    const newMarker = this.createInitiativeMarker(id, this.props.initiatives[id]);
    const updatedMarkers = { ...this.state.markers };
    updatedMarkers[id] = newMarker;
    this.setState({ markers: updatedMarkers, newInitiativeMarker: null });
  }

  selectInitiativeOnMap(selectedInitiativeId, prevSelectedInitiativeId) {
    if(prevSelectedInitiativeId) {
      const el = this.state.markers[prevSelectedInitiativeId].getElement();
      const initiativeData = this.props.initiatives[prevSelectedInitiativeId];
      el.style.width = '15px';
      el.style.height = '15px';
      if(initiativeData.completionDate) el.style.boxShadow = '0px 0px 15px 8px #ffeab0';
    }
    if(selectedInitiativeId) {
      const el = this.state.markers[selectedInitiativeId].getElement();
      const initiativeData = this.props.initiatives[selectedInitiativeId];
      el.style.width = '23px';
      el.style.height = '23px';
      if(initiativeData.completionDate) el.style.boxShadow = '0px 0px 15px 10px #ffeab0';
      this.shiftMapToCenterInitiative(initiativeData.coordinates);
    }
  }

  completeInitiativeonMap() {
    const el = this.state.markers[this.props.changeMapTrigger.id].getElement();
    el.style.opacity = '1';
    el.style.boxShadow = '0px 0px 15px 10px #ffeab0';
  }

  deleteInitiativeFromMap() {
    const markerId = this.props.changeMapTrigger.id;
    const updatedMarkers = { ...this.state.markers };
    updatedMarkers[markerId].remove();
    delete updatedMarkers[markerId];
    this.setState({ markers: updatedMarkers });
  }

  deleteInitiativesFromMap() {
    const updatedMarkers = { ...this.state.markers };
    this.props.changeMapTrigger.ids.forEach(markerId => {
      updatedMarkers[markerId].remove();
      delete updatedMarkers[markerId];
    })
    this.setState({ markers: updatedMarkers });
  }

  updateFiltersOnMap() {
    const teamsSelection = {};
    this.props.teams.forEach(team => {
      teamsSelection[team.id] = team.selected;
    })
    for(let id in this.state.markers) {
      const teamId = this.props.initiatives[id].teamId;
      this.state.markers[id].getElement().style.visibility = (teamsSelection[teamId])
        ? 'visible'
        : 'hidden';
    }  
  }

  render() {
    const mapContainerStyle = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%'
    };
    return (
      <div 
        ref={this.mapContainer}
        style={mapContainerStyle}
        onClick={this.props.closeNavbar}
      >
      </div>
    );
  }
}

export default Map;