import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// Initialize Firebase
const config = {
	apiKey: "AIzaSyCS-AKAA2zqNh3DjL2sRkKgkG3TPtMGeEs",
	authDomain: "malaga-luce.firebaseapp.com",
	databaseURL: "https://malaga-luce.firebaseio.com",
	projectId: "malaga-luce",
	storageBucket: "malaga-luce.appspot.com",
	messagingSenderId: "925498624938"
};
firebase.initializeApp(config);

export const firebaseRef = firebase;
export const functions = firebase.app().functions('europe-west1');
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();