import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from '../Auth/AuthForm.module.scss';
import { functions } from '../../config/firebase';
import { NOTIFICATION_TYPE } from '../../config';

class ContactForm extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
			formData: this.resetFormData(),
			isSending: false,
			errorMessage: null
	  };
	}

	resetFormData() {
		return {
			name: {
				value: '',
				validation: {
					required: true
				},
				valid: true,
				errorMessage: null
			},
			email: {
				value: '',
				validation: {
					required: true,
					email: true
				},
				valid: true,
				errorMessage: null
			},
			subject: {
				value: '',
				validation: {},
				valid: true,
				errorMessage: null
			},
			message: {
				value: '',
				validation: {
					required: true
				},
				valid: true,
				errorMessage: null
			}
		};
	}

	changeFormData = e => {
		const inputName = e.target.name;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		updatedFormInput.value = e.target.value;
		updatedFormData[inputName] = updatedFormInput;
 		this.setState({ formData: updatedFormData });		
	}

	changeFormDataValidity = e => {
		const inputName = e.target.name;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };		
		updatedFormInput.value = updatedFormInput.value.trim();
		const { valid, errorMessage } = this.checkValidity(inputName, updatedFormInput.value, updatedFormInput.validation);
		updatedFormInput.valid = valid;
		updatedFormInput.errorMessage = errorMessage;
		updatedFormData[inputName] = updatedFormInput;
  	this.setState({ formData: updatedFormData });
	}

	checkValidity(inputName, value, rules) {
		if(rules.required && value === '') {
			return { valid: false, errorMessage: this.props.t('auth.validationErrors.required') };
		}
		if(rules.email) {
			const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
			if(!emailRegex.test(value)) {
				return { valid: false, errorMessage: this.props.t('auth.validationErrors.email') };
			}
		}
		return { valid: true, errorMessage: null };
	}

	checkFullFormValidity() {
		let isFormValid = true;	
		const updatedFormData = { ...this.state.formData };		
		Object.keys(updatedFormData).forEach(inputName => {
			const updatedFormInput = { ...updatedFormData[inputName] };
			updatedFormInput.value = updatedFormInput.value.trim();
			const { valid, errorMessage } = this.checkValidity(inputName, updatedFormInput.value, updatedFormInput.validation);
			if(!valid) isFormValid = false;
			updatedFormInput.valid = valid;
			updatedFormInput.errorMessage = errorMessage;
			updatedFormData[inputName] = updatedFormInput;			
		});
		this.setState({ formData: updatedFormData });
		return isFormValid;
	}

	sendEmail = e => {
		e.preventDefault();
		if(!this.checkFullFormValidity()) return;
		this.setState({ isSending: true, errorMessage: null });
		const { formData } = this.state;
		const emailData = {};
		for (let inputName in formData) {
			emailData[inputName] = formData[inputName].value;
		}
		const sendEmail = functions.httpsCallable('sendEmail');
		sendEmail(emailData)
			.then(res => {
				this.props.closePageModalWithNotification({
					type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('contact.success')
				});
			})
			.catch(error => {
				this.setState({ isSending: false, errorMessage: this.props.t('contact.error') });
			});
	}

	render() {
		const { t } = this.props;
		const { formData, isSending, errorMessage } = this.state;
		return(
			<React.Fragment>
				<h1 className={classes.header}>{t('contact.contactUs')}</h1>
				{errorMessage && (
					<p className={classes.authErrorMessage}>{errorMessage}</p>
				)}
				<form onSubmit={this.sendEmail}> 
					<div className={classes.formFields}>
						<div className={`field ${classes.field}`}>
							<div className='control'>
								<input 
									className={`input ${classes.input} ${formData.name.valid ? '' : classes.invalid}`}
									name='name' 
									type='text'
									placeholder={t('contact.name')}
									value={formData.name.value}
									onChange={this.changeFormData}
									onBlur={this.changeFormDataValidity}
								/>
							</div>
							{!formData.name.valid && (
								<p className={classes.inputErrorMessage}>{formData.name.errorMessage}</p>
							)}
						</div>
						<div className={`field ${classes.field}`}>
							<div className='control'>
								<input 
									className={`input ${classes.input} ${formData.email.valid ? '' : classes.invalid}`}
									name='email' 
									type='text'
									placeholder={t('contact.email')}
									value={formData.email.value}
									onChange={this.changeFormData}
									onBlur={this.changeFormDataValidity}
								/>
							</div>
							{!formData.email.valid && (
								<p className={classes.inputErrorMessage}>{formData.email.errorMessage}</p>
							)}
						</div>
						<div className={`field ${classes.field}`}>
							<div className='control'>
								<input 
									className={`input ${classes.input} ${formData.subject.valid ? '' : classes.invalid}`}
									name='subject' 
									type='text'
									placeholder={t('contact.subject')}
									value={formData.subject.value}
									onChange={this.changeFormData}
								/>
							</div>
							{!formData.subject.valid && (
								<p className={classes.inputErrorMessage}>{formData.subject.errorMessage}</p>
							)}
						</div>
						<div className={`field ${classes.field}`}>
							<div className='control'>
								<textarea 
									className={`input ${classes.input} ${formData.message.valid ? '' : classes.invalid}`}
									style={{ minHeight: '180px', minWidth: '100%' }}
									name='message' 
									placeholder={t('contact.message')}
									value={formData.message.value}
									onChange={this.changeFormData}
									onBlur={this.changeFormDataValidity}
								/>
							</div>
							{!formData.message.valid && (
								<p className={classes.inputErrorMessage}>{formData.message.errorMessage}</p>
							)}
						</div>						
					</div>
					<button
						className={`button ${classes.submitButton} ${isSending ? 'is-loading' : ''}`}
						onClick={this.sendEmail}
					>
						{!isSending ? t('contact.send') : null}
					</button>
				</form>
			</React.Fragment>
		);
	}
}

export default withTranslation()(ContactForm);