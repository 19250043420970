import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Navbar.module.scss';
import { NAVIGATION, AUTH_STATUS } from '../../config';
import { ReactComponent as AuthIcon } from '../../assets/icons/auth.svg';
import { ReactComponent as AdminIcon } from '../../assets/icons/admin.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';

class Navbar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showDropdown: false
		};
	}

	toggleDropdown = () => {
		this.setState(state => ({ showDropdown: !state.showDropdown }));
	}

	editTeamClickHandler = () => {
		this.props.navigateTo(NAVIGATION.EDIT_TEAM);
		this.setState({ showDropdown: false });		
	}

	deleteTeamClickHandler = () => {
		this.props.navigateTo(NAVIGATION.DELETE_TEAM);
		this.setState({ showDropdown: false });		
	}

	logoutClickHandler = () => {
		this.props.logout();
		this.setState({ showDropdown: false });
	}

	render() {
		const {
			authStatus,
			navbarMenuIsShown,
			admin,
			teamData,
			showNavbarMenu,
			navigateTo,
			openTeamsList,
			t
		} = this.props;
		const { showDropdown } = this.state;

		let authButtonContent = null;
		switch (authStatus) {
			case AUTH_STATUS.NOT_AUTHENTICATED:
				authButtonContent = (
					<div className={classes.authButtonContent} onClick={navigateTo.bind(this, NAVIGATION.AUTH)}>
						{t('navbar.auth')}
					</div>
				);
				break;
			case AUTH_STATUS.AUTHENTICATED:
				authButtonContent = (
					<React.Fragment>
						<div className={classes.authButtonContent} onClick={this.toggleDropdown}>
							{admin ? (
								<AdminIcon className={classes.adminIcon}/>
							) : teamData && (
								<AuthIcon 
									className={classes.authIcon} 
									style={{ fill: teamData.color}}
								/>
							)}
							<span className={classes.authText}>
								{admin ? t('navbar.admin') : t('navbar.account')}
							</span>
							<ArrowDownIcon className={classes.arrowDownIcon} />
						</div>
						<div className={`${classes.dropdown} ${showDropdown ? classes.show : ''}`}>
							<ul>
								{!admin && (
									<React.Fragment>
										<li onClick={this.editTeamClickHandler}>{t('navbar.edit')}</li>
										<li onClick={this.deleteTeamClickHandler}>{t('navbar.delete')}</li>
									</React.Fragment>
								)}
								<li onClick={this.logoutClickHandler}>{t('navbar.logout')}</li>
							</ul>
						</div>
					</React.Fragment>
				);
				break;
			default:
				break;
		}
		const isAuthenticating = (authStatus === AUTH_STATUS.AUTHENTICATING);

		return(
				<nav className={`navbar ${classes.navbar}`}>
				  <div className={`navbar-brand ${classes.navbarBrand}`}>
				    <a href='/' className={`navbar-item ${classes.logoContainer}`}>
				      <img src={process.env.PUBLIC_URL + '/logo.png'} className={classes.logo} alt='logo' />
				    </a>
				    <div className={`navbar-item ${classes.navbarBurgerContainer}`}>
					    <button 
					    	className={`navbar-burger button ${classes.navbarBurger} ${navbarMenuIsShown ? classes.close : ''}`}
					    	onClick={showNavbarMenu}
					    >
					      <span></span>
					      <span></span>
					      <span></span>
					    </button>
				    </div>
				  </div>
				  <div className={`navbar-menu ${classes.navbarMenu} ${navbarMenuIsShown ? classes.shown : ''}`}>
				    <div className={`navbar-end ${classes.navbarMenuItems}`}>
				      <div className={`navbar-item ${classes.navbarItem}`}>
					      <button
					      	className={`navbar-item button ${classes.navbarButton}`}
					      	onClick={openTeamsList}
					      >
					        {t('navbar.teams')}
					      </button>
				      </div>
				      <div className={`navbar-item ${classes.navbarItem}`}>
					      <a
					      	className={`${classes.navbarButton}`}
					      	href='https://www.youtube.com/watch?v=ahsu1j9CYu8'
					      	target='_blank'
									rel='noopener noreferrer'
					      >
					        {t('navbar.video')}
					      </a>
				      </div>
				     	<div className={`navbar-item ${classes.navbarItem}`}>
					     	<button
					     		className={`navbar-item button ${classes.navbarButton}`}
					     		onClick={navigateTo.bind(this, NAVIGATION.CONTACT)}
					     	>
					        {t('navbar.contact')}
					      </button>
				     	</div>
				     	<div className={`navbar-item ${classes.navbarItem}`}>
					     	<button
					     		className={`navbar-item button ${classes.navbarButton}`}
					     		onClick={navigateTo.bind(this, NAVIGATION.FAQ)}
					     	>
					        {t('navbar.faq')}
					      </button>
				     	</div>
				     	<div className={`navbar-item ${classes.navbarItem}`}>
					     	<button className={`navbar-item button ${classes.navbarButton} ${classes.authButton} ${isAuthenticating ? 'is-loading' : ''}`}>
									{authButtonContent}
				      	</button>		      
				     	</div>
				    </div>
				  </div>
				</nav>
		);

	}
}

export default withTranslation()(Navbar);