import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Faq.module.scss';
import { NUM_QUESTIONS_FAQ } from '../../config';
import { ReactComponent as OpenIcon } from '../../assets/icons/add.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/delete.svg';

class Faq extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
			openIdx: 0
	  };
	}

	changeFaqIndex = (idx) => {
		this.setState(state => state.openIdx === idx ? { openIdx: null } : { openIdx: idx });
	}

	render() {
		const{ t } = this.props;
		const{ openIdx } = this.state;
		return(
			<React.Fragment>
				<h1 className={classes.header}>{t('faq.faq')}</h1>
				{Array(NUM_QUESTIONS_FAQ).fill().map((x, index) => (
					<div
						key={index}
						className={classes.faqBlock}
						onClick={this.changeFaqIndex.bind(this, index)}
					>
						<div className={classes.question}>
							<p className={classes.questionText}>
								{t(`faq.questions.${index}`)}
							</p>
							{(index === openIdx) ? (
								<CloseIcon className={classes.questionIcon} />
							) : (
								<OpenIcon className={classes.questionIcon} />
							)}
						</div>
						{(index === openIdx) && t(`faq.answers.${index}`).split('\n').map((paragraph, pIdx) => (
							<p key={pIdx} className={classes.answer}>
								{paragraph}
							</p>
						))}
					</div>
				))}
			</React.Fragment>
		);
	}
}

export default withTranslation()(Faq);