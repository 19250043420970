import React from 'react';
import classes from './NewInitiativeButton.module.scss';
import { ReactComponent as NewInitiativeIcon } from '../../assets/icons/new-initiative.svg';

const NewInitiativeButton = ({ newInitiativeButtonIsSelected, selectNewInitiativeButton }) => {
	return(
		<div className={classes.container}>
		<button
			className={`${classes.newInitiativeButton} ${newInitiativeButtonIsSelected ? classes.selected : ''}`}
			onClick={selectNewInitiativeButton}
		>
			<NewInitiativeIcon className={classes.icon}/>
		</button>
		</div>
	);
}

export default NewInitiativeButton;