import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './DeleteInitiative.module.scss';
import { functions } from '../../config/firebase';

class DeleteInitiative extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
			isSending: false
	  };
	}

	deleteButtonClickedHandler = e => {
		this.setState({ isSending: true });
		const data = { initiativeId: this.props.selectedInitiativeId };
		const deleteInitiativeServer = functions.httpsCallable('deleteInitiative');
		return deleteInitiativeServer(data)
			.then(() => {
				this.props.deleteInitiative();
				this.props.closePageModal();
			})
			.catch(error => {
				this.props.deleteInitiativeError();
				this.props.closePageModal();		
			});
	}

	render() {
		const { isSending } = this.state;
		return(
			<React.Fragment>
				<h1 className={classes.header}>{this.props.t('deleteInitiative.header')}</h1>
				<p className={classes.question}>{this.props.t('deleteInitiative.question')}</p>
				<div className={classes.note}>
					<p>{this.props.t('deleteInitiative.note1')}</p>
					<p>{this.props.t('deleteInitiative.note2')}</p>
				</div>
				<div className={classes.buttonsContainer}>
					<button 
						className={`button ${classes.button} ${classes.delete} ${isSending ? 'is-loading' : ''}`}
						onClick={!isSending ? this.deleteButtonClickedHandler : null}
					>
						{isSending ? null : this.props.t('deleteInitiative.deleteButton')}
					</button>
					<button 
						className={`button ${classes.button}`}
						onClick={!isSending ? this.props.closePageModal : null}
					>
						{this.props.t('deleteInitiative.cancelButton')}
					</button>
				</div>
			</React.Fragment>
		);
	}


}

export default withTranslation()(DeleteInitiative);