import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { auth, db } from './config/firebase';
import Map from './components/Map/Map';
import Navbar from './components/Navbar/Navbar';
import Welcome from './components/Welcome/Welcome';
import NewInitiativeButton from './components/NewInitiativeButton/NewInitiativeButton';
import PageModal from './components/PageModal/PageModal';
import Auth from './components/Auth/Auth';
import CreateEditInitiative from './components/Initiative/CreateEditInitiative';
import DeleteInitiative from './components/Initiative/DeleteInitiative';
import InitiativePopup from './components/InitiativePopup/InitiativePopup';
import TeamsList from './components/TeamsList/TeamsList';
import TeamAdmin from './components/TeamAdmin/TeamAdmin';
import ContactForm from './components/ContactForm/ContactForm';
import Faq from './components/Faq/Faq';
import Notification from './components/Notification/Notification';
import { NAVIGATION, AUTH_STATUS, CHANGE_MAP, NOTIFICATION_TYPE } from './config';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authStatus: AUTH_STATUS.AUTHENTICATING,
      navigation: NAVIGATION.WELCOME,
      navbarMenuIsShown: false,
      admin: false,
      teamData: null,
      initiatives: null,
      teams: null,
      teamsListIsShown: true,
      selectedTeamAdminId: null,
      changeMapTrigger: null,
      newInitiativeButtonIsSelected: false,
      newInitiativeCoordinates: null,
      notification: null,
      selectedInitiativeId: null
    };
  }

  componentDidMount() {
    // Set up observer on Firebase auth object
    auth.onAuthStateChanged(user => {
      if (user) {
        user.getIdTokenResult()
          .then(idTokenResult => {
            if (!!idTokenResult.claims.admin) {
              this.setState({ 
                authStatus: AUTH_STATUS.AUTHENTICATED, 
                admin: true,
                notification: { type: NOTIFICATION_TYPE.ADMIN_LOGIN }
              });
            } else {
              this.loadTeamDataAndSetAuthenticated(user.uid);
            }
          })
          .catch(error => {
            console.log("Cannot load user id token", error);
            this.loadTeamDataAndSetAuthenticated(user.uid);
          });
      } else {
        this.setState({ 
          authStatus: AUTH_STATUS.NOT_AUTHENTICATED,
          admin: false,
          teamData: null,
          newInitiativeButtonIsSelected: false
      });
      }
    });
    this.loadInitiativesAndTeams();
    this.setupFacebookSDK();
  }

  loadTeamDataAndSetAuthenticated(teamId) {
    let teamData = {};
    db.collection('teams').doc(teamId)
      .get()
      .then(doc => {
        if(doc.exists) {
          teamData = { id: doc.id, ...doc.data() };
          this.setState({ 
            authStatus: AUTH_STATUS.AUTHENTICATED, 
            teamData,
            notification: { type: NOTIFICATION_TYPE.LOGIN }
          });
        } else {
          this.setState({
            authStatus: AUTH_STATUS.AUTHENTICATED,
            notification: { type: NOTIFICATION_TYPE.ERROR, message: this.props.t('appErrors.teamDataLoad') }
          });
        }
      })
      .catch(err => {
        this.setState({
          authStatus: AUTH_STATUS.AUTHENTICATED,
          notification: { type: NOTIFICATION_TYPE.ERROR, message: this.props.t('appErrors.teamDataLoad') }
        });
      });    
  }

  logoutHandler = () => {
    if(this.state.notification && this.state.notification.type === NOTIFICATION_TYPE.LOGIN) {
      this.setState({ notification: null });
    }
    auth.signOut();
  }

  loadInitiativesAndTeams() {
    let initiatives = {};
    db.collection('initiatives')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          initiatives[doc.id] = doc.data();
        });
        return this.loadTeamsAndSortRanking(initiatives);
      })
      .then(teams => {
        this.setState({
          initiatives,
          teams,
          changeMapTrigger: { action: CHANGE_MAP.LOAD_INITIATIVES }
        });
      })
      .catch(err => {
        this.setState({ notification: { type: NOTIFICATION_TYPE.ERROR, message: this.props.t('appErrors.teamDataLoad') } });
      }); 
  }

  loadTeamsAndSortRanking(initiatives) {
    const teams = [];
    return db.collection('teams')
      .get()
      .then(querySnapshot => {
        const initiativesDataArray = Object.values(initiatives);
        querySnapshot.forEach(doc => {
          const teamInitiativesDataArray = initiativesDataArray.filter(elem => elem.teamId === doc.id);
          const numCompleted = teamInitiativesDataArray.reduce((acc, elem) => elem.completionDate ? acc + 1 : acc, 0); 
          const numCreated = teamInitiativesDataArray.reduce((acc, elem) => elem.creationDate ? acc + 1 : acc, 0); 
          const lastCompleted = teamInitiativesDataArray.reduce((acc, elem) => (elem.completionDate && elem.completionDate > acc) ? elem.completionDate : acc, 0);
          const lastCreated = teamInitiativesDataArray.reduce((acc, elem) => (elem.creationDate && elem.creationDate > acc) ? elem.creationDate : acc, 0);
          teams.push({
            id: doc.id,
            ...doc.data(),
            numCompleted,
            numCreated,
            lastCompleted,
            lastCreated,
            selected: true
          });
        });
        return this.sortTeamsArray(teams);
      });    
  }

  sortTeamsArray(teams) {
    return [ ...teams ].sort((a,b) => {
      if(a.numCompleted < b.numCompleted) return 1;
      if(a.numCompleted > b.numCompleted) return -1;
      if(a.lastCompleted < b.lastCompleted) return 1;
      if(a.lastCompleted > b.lastCompleted) return -1;
      if(a.lastCreated < b.lastCreated) return 1;
      if(a.lastCreated > b.lastCreated) return -1;
      if(a.creationDate < b.creationDate) return -1;
      if(a.creationDate > b.creationDate) return 1;
      return 0;
    });
  }

  onFinishMapLoadingHandler = () => {
    if(window.location.search) {
      const idParam = new URLSearchParams(document.location.search).get('id');
      if(idParam && this.state.initiatives[idParam]) {
        this.setState({ selectedInitiativeId: idParam });
      }
    }
  }

  setupFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : '2358688384407673',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.2'
      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  changeAuthStatusHandler = (authStatus) => {
    this.setState({ authStatus: authStatus });
  }

  navigateToHandler = (navigation) => {
    this.setState(state => {
      if(state.navbarMenuIsShown) {
        return { navbarMenuIsShown: false, navigation: navigation };
      } else {
        return { navigation: navigation };
      }
    });
  }

  closeNavbarHandler = () => {
    this.setState({ navbarMenuIsShown: false });
  }

  showNavbarMenuHandler = () => {
    this.setState(state => ({ navbarMenuIsShown: !state.navbarMenuIsShown }));
  }

  showNotificationHandler = (notification) => {
    this.setState({ notification });
  }

  handleDBLoadError = (errorMessage) => {
    this.setState({
      navigation: NAVIGATION.MAP,
      notification: { type: NOTIFICATION_TYPE.ERROR, message: errorMessage }
    });
  }

  closePageModalWithNotificationHandler = (notification) => {
    this.setState({
      navigation: NAVIGATION.MAP,
      notification
    });
  }

  addNewTeamHandler = (teamData) => {
    const newTeam = {
      ...teamData,
      numCompleted: 0,
      lastCompleted: 0,
      numCreated: 0,
      lastCreated: 0,
      selected: true
    };
    const updatedTeams = [ ...this.state.teams, newTeam ];
    this.setState({
      teams: this.sortTeamsArray(updatedTeams),
      notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('auth.signupSuccess') }
    });
  }

  updateTeamInAppHandler = (updatedTeamDataInDB, updatedTeamInitiativesIds) => {
    const updatedState = {
      notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('auth.editSuccess') },
      navigation: NAVIGATION.MAP
    };
    if(updatedTeamDataInDB !== null) {
      updatedState.teamData = {
        ...this.state.teamData,
        ...updatedTeamDataInDB
      };
      if(updatedTeamDataInDB.name) {
        updatedState.teams = this.state.teams.map(team => {
          if(team.id === auth.currentUser.uid) {
            return { ...team, name: updatedTeamDataInDB.name };
          } else {
            return team;
          }
        });
      }
      if(updatedTeamInitiativesIds.length > 0) {
        const updatedInitiativeData = {};
        if(updatedTeamDataInDB.name) updatedInitiativeData.teamName = updatedTeamDataInDB.name;
        if(updatedTeamDataInDB.members) updatedInitiativeData.teamMembers = updatedTeamDataInDB.members;
        const updatedInitiatives = {};
        updatedTeamInitiativesIds.forEach(initiativeId => {
          updatedInitiatives[initiativeId] = {
            ...this.state.initiatives[initiativeId],
            ...updatedInitiativeData
          }
        });
        updatedState.initiatives = {
          ...this.state.initiatives,
          ...updatedInitiatives
        };
      }      
    }
    this.setState(updatedState);
  }

  deleteTeamInAppHandler = (deletedTeamInitiativesIds) => {
    const deletedTeamId = this.state.admin ? this.state.selectedTeamAdminId : auth.currentUser.uid;
    const updatedState = {
      notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('auth.deleteSuccess') },
      navigation: NAVIGATION.MAP,
      teams: this.state.teams.filter(team => team.id !== deletedTeamId),
      selectedTeamAdminId: null
    };
    if(deletedTeamInitiativesIds.length > 0) {
      updatedState.initiatives = { ...this.state.initiatives };
      deletedTeamInitiativesIds.forEach(initiativeId => {
        delete updatedState.initiatives[initiativeId];
      });
      updatedState.changeMapTrigger = { action: CHANGE_MAP.DELETE_INITIATIVES, ids: deletedTeamInitiativesIds };
    }
    this.setState(updatedState);
    if(!this.state.admin) auth.signOut();
  }

  selectNewInitiativeButtonHandler = () => {
    if(this.state.admin) return;
    this.setState(state => {
      if(state.authStatus === AUTH_STATUS.AUTHENTICATED) {
        return { newInitiativeButtonIsSelected: !state.newInitiativeButtonIsSelected };
      } else {
        return { navigation: NAVIGATION.AUTH };
      }
    });
  }

  pinNewInitiativeHandler = e => {
    const newInitiativeCoordinates = [ e.lngLat.lng, e.lngLat.lat ];
    this.setState({
      newInitiativeCoordinates,
      newInitiativeButtonIsSelected: false,
      selectedInitiativeId: null,
      changeMapTrigger: { action: CHANGE_MAP.PIN_INITIATIVE }
    });
    setTimeout(() => {
      this.setState({ navigation: NAVIGATION.NEW_INITIATIVE });
    }, 300);
  }

  cancelNewInitiativeHandler = () => {
    this.setState({
      newInitiativeCoordinates: null,
      changeMapTrigger: { action: CHANGE_MAP.CANCEL_INITIATIVE }
    });
  }

  cancelEditInitiativeHandler = () => {
    this.setState({ selectedInitiativeId: null });
  }
  
  addNewInitiativeHandler = (initiativeId, initiativeData) => {
    this.setState(state => {
      const updatedInitiatives = { ...state.initiatives };
      updatedInitiatives[initiativeId] = initiativeData;
      let updatedTeams = state.teams.map(team => {
        if(team.id === initiativeData.teamId) {
          const updatedTeam = {
            numCreated: team.numCreated + 1,
            lastCreated: initiativeData.creationDate
          };
          if(initiativeData.completionDate) {
            updatedTeam['lastCompleted'] = initiativeData.completionDate;
            updatedTeam['numCompleted'] = team.numCompleted + 1;
          }
          return {
            ...team,
            ...updatedTeam
          };
        } else {
          return team;
        }      
      });
      return {
        initiatives: updatedInitiatives,
        teams: this.sortTeamsArray(updatedTeams),
        newInitiativeCoordinates: null,
        navigation: NAVIGATION.MAP,
        changeMapTrigger: { action: CHANGE_MAP.ADD_INITIATIVE, id: initiativeId },
        notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('createEditInitiative.successCreate') }
      };
    });
  }

  updateInitiativeHandler = (updatedInitiativeData) => {
    this.setState(state => {
      const initiativeId = state.selectedInitiativeId;
      const updatedInitiatives = {
        ...state.initiatives,
        [initiativeId]: {
          ...state.initiatives[initiativeId],
          ...updatedInitiativeData
        }
      };
      if(updatedInitiativeData.beforeImageUrl) {
        updatedInitiatives[initiativeId].beforeImageUrl = updatedInitiativeData.beforeImageUrl;
      }
      if(updatedInitiativeData.afterImageUrl) {
        updatedInitiatives[initiativeId].afterImageUrl = updatedInitiativeData.afterImageUrl;
      }
      const updatedState = {
        initiatives: updatedInitiatives,
        navigation: NAVIGATION.MAP,
        notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('createEditInitiative.successUpdate') }
      };
      if(updatedInitiativeData.completionDate) {
        updatedState['changeMapTrigger'] = { action: CHANGE_MAP.COMPLETE_INITIATIVE, id: initiativeId };
        updatedState['notification'] = { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('createEditInitiative.successComplete') }
        let updatedTeams = state.teams.map(team => {
          if(team.id === updatedInitiatives[initiativeId].teamId) {
            return {
              ...team,
              lastCompleted: updatedInitiativeData.completionDate,
              numCompleted: team.numCompleted + 1
            };
          } else {
            return team;
          }      
        });
        updatedState['teams'] = this.sortTeamsArray(updatedTeams);
      }
      return updatedState;
    });
  }

  deleteInitiativeHandler = () => {
    this.setState(state => {
      const updatedInitiatives = { ...state.initiatives };
      const initiativeId = state.selectedInitiativeId;
      delete updatedInitiatives[initiativeId];
      let updatedTeams = state.teams.map(team => {
        if(team.id === state.initiatives[initiativeId].teamId) {
          const updatedTeam = { numCreated: team.numCreated - 1 };
          if(state.initiatives[initiativeId].completionDate) {
            updatedTeam['numCompleted'] = team.numCompleted - 1;
          }
          if(state.initiatives[initiativeId].creationDate === team.lastCreated ||
            state.initiatives[initiativeId].completionDate === team.lastCompleted) {
            const initiativesDataArray = Object.values(updatedInitiatives);
            const teamInitiativesDataArray = initiativesDataArray.filter(elem => elem.teamId === state.initiatives[initiativeId].teamId);
            if(state.initiatives[initiativeId].creationDate === team.lastCreated) {
              updatedTeam['lastCreated'] = teamInitiativesDataArray.reduce((acc, elem) => (elem.creationDate && elem.creationDate > acc) ? elem.creationDate : acc, 0);
            }
            if(state.initiatives[initiativeId].completionDate === team.lastCompleted) {
              updatedTeam['lastCompleted'] = teamInitiativesDataArray.reduce((acc, elem) => (elem.completionDate && elem.completionDate > acc) ? elem.completionDate : acc, 0);
            }
          }
          return {
            ...team,
            ...updatedTeam
          };
        } else {
          return team;
        }      
      });
      return {
        initiatives: updatedInitiatives,
        teams: this.sortTeamsArray(updatedTeams),
        selectedInitiativeId: null,
        changeMapTrigger: { action: CHANGE_MAP.DELETE_INITIATIVE, id: this.state.selectedInitiativeId },
        notification: { type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('deleteInitiative.success') }
      };
    });
  }

  deleteInitiativeErrorHandler = () => {
    this.setState({ 
      notification: { type: NOTIFICATION_TYPE.ERROR, message: this.props.t('deleteInitiative.serverError') }
    });
  }

  selectInitiativeHandler = (initiativeId) => {
    this.setState({ selectedInitiativeId: initiativeId, teamsListIsShown: false });
  }

  closePopupHandler = () => {
    this.setState({ selectedInitiativeId: null });
  }

  openTeamsListHandler = () => {
    this.setState({
      teamsListIsShown: true,
      selectedInitiativeId: null,
      navbarMenuIsShown: false,
      navigation: NAVIGATION.MAP
    });
  }

  changeTeamsSelectionHandler = e => {
    const updatedTeams = this.state.teams.map(team => {
      if(team.id === e.target.name) {
        return {
          ...team,
          selected: e.target.checked
        };
      } else {
        return team;
      }
    });
    this.setState({
      teams: updatedTeams,
      changeMapTrigger: { action: CHANGE_MAP.UPDATE_FILTERS }
    });
  }

  changeAllTeamsSelectionHandler = (selected) => {
    const updatedTeams = this.state.teams.map(team => ({
      ...team,
      selected
    }));
    this.setState({
      teams: updatedTeams,
      changeMapTrigger: { action: CHANGE_MAP.UPDATE_FILTERS }
    });
  }

  closeTeamsListHandler = () => {
    this.setState({ teamsListIsShown: false });
  }

  closeNotificationHandler = () => {
    this.setState({ notification: null });
  }

  openTeamAdminHandler = (teamId) => {
    this.setState({
      navigation: NAVIGATION.TEAM_ADMIN,
      selectedInitiativeId: null,
      selectedTeamAdminId: teamId
    });
  }

  render() {
    const {
      authStatus,
      navigation,
      navbarMenuIsShown,
      admin,
      teamData,
      initiatives,
      selectedInitiativeId,
      teams,
      teamsListIsShown,
      changeMapTrigger,
      newInitiativeButtonIsSelected,
      newInitiativeCoordinates,
      notification,
      selectedTeamAdminId
    } = this.state;

    const style = {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      height: '100%'
    };

    let pageModalContent = null;
    switch(navigation) {
      case NAVIGATION.WELCOME:
        pageModalContent = (
          <Welcome
            navigateToContact={this.navigateToHandler.bind(this, NAVIGATION.CONTACT)}
          />
        );
        break;
      case NAVIGATION.AUTH:
        pageModalContent = (
          <Auth
            authStatus={authStatus}
            changeAuthStatus={this.changeAuthStatusHandler}
            showNotification={this.showNotificationHandler}
            handleDBLoadError={this.handleDBLoadError}
            addNewTeam={this.addNewTeamHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.EDIT_TEAM:
        pageModalContent = (
          <Auth
            editTeam={true}
            teamData={teamData}
            handleDBLoadError={this.handleDBLoadError}
            updateTeamInApp={this.updateTeamInAppHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.DELETE_TEAM:
        pageModalContent = (
          <Auth
            deleteTeam={true}
            admin={admin}
            selectedTeamAdminId={selectedTeamAdminId}
            deleteTeamInApp={this.deleteTeamInAppHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.NEW_INITIATIVE:
        pageModalContent = (
          <CreateEditInitiative
            isNew={true}
            newInitiativeCoordinates={newInitiativeCoordinates}
            teamColor={teamData ? teamData.color : null}
            addNewInitiative={this.addNewInitiativeHandler}
            cancelNewInitiative={this.cancelNewInitiativeHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.EDIT_INITIATIVE:
        pageModalContent = (
          <CreateEditInitiative
            isNew={false}
            selectedInitiativeId={selectedInitiativeId}
            initiativeData={selectedInitiativeId ? initiatives[selectedInitiativeId] : null}
            teamColor={teamData ? teamData.color : null}
            updateInitiative={this.updateInitiativeHandler}
            cancelEditInitiative={this.cancelEditInitiativeHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.DELETE_INITIATIVE:
        pageModalContent = (
          <DeleteInitiative
            selectedInitiativeId={selectedInitiativeId}
            deleteInitiative={this.deleteInitiativeHandler}
            deleteInitiativeError={this.deleteInitiativeErrorHandler}
            closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
          />
        );
        break;
      case NAVIGATION.CONTACT:
        pageModalContent = (
          <ContactForm
            closePageModalWithNotification={this.closePageModalWithNotificationHandler}
          />
        );
        break;
      case NAVIGATION.FAQ:
        pageModalContent = (
          <Faq
          />
        );
        break;         
      case NAVIGATION.TEAM_ADMIN:
        pageModalContent = (
          <TeamAdmin
            teamData={teams.find(team => team.id === selectedTeamAdminId)}
            handleDBLoadError={this.handleDBLoadError}
            confirmDelete={this.navigateToHandler.bind(this, NAVIGATION.DELETE_TEAM)}
          />
        );
        break;        
      default:
        break;
    }

    return (
      <div style={style}>
        <Map
          changeMapTrigger={changeMapTrigger}
          initiatives={initiatives}
          teams={teams}
          selectedInitiativeId={selectedInitiativeId}
          newInitiativeButtonIsSelected={newInitiativeButtonIsSelected}
          teamColor={teamData ? teamData.color : null}
          newInitiativeCoordinates={newInitiativeCoordinates}
          onFinishMapLoading={this.onFinishMapLoadingHandler}
          pinNewInitiative={this.pinNewInitiativeHandler}
          selectInitiative={this.selectInitiativeHandler}
          closeNavbar={navbarMenuIsShown ? this.closeNavbarHandler : null}
        />
        <Navbar
          authStatus={authStatus}
          navbarMenuIsShown={navbarMenuIsShown}
          navigation={navigation}
          admin={admin}
          teamData={teamData}
          showNavbarMenu={this.showNavbarMenuHandler}
          navigateTo={this.navigateToHandler}
          openTeamsList={this.openTeamsListHandler}
          logout={this.logoutHandler}
        />
        <Notification
          notification={notification}
          teamData={teamData}
          closeNotification={this.closeNotificationHandler}
        />
        <NewInitiativeButton
          newInitiativeButtonIsSelected={newInitiativeButtonIsSelected}
          selectNewInitiativeButton={this.selectNewInitiativeButtonHandler}
        />
        <PageModal 
          show={navigation !== NAVIGATION.MAP}
          closePageModal={this.navigateToHandler.bind(this, NAVIGATION.MAP)}
        >
          {pageModalContent}
        </PageModal>
        <InitiativePopup
          show={selectedInitiativeId && navigation !== NAVIGATION.EDIT_INITIATIVE}
          isAuthenticated={authStatus === AUTH_STATUS.AUTHENTICATED}
          admin={admin}
          selectedInitiativeId={selectedInitiativeId}
          initiativeData={selectedInitiativeId ? initiatives[selectedInitiativeId] : null}
          editInitiative={this.navigateToHandler.bind(this, NAVIGATION.EDIT_INITIATIVE)}
          deleteInitiative={this.navigateToHandler.bind(this, NAVIGATION.DELETE_INITIATIVE)}
          openTeamAdmin={this.openTeamAdminHandler}
          closePopup={this.closePopupHandler}
        />
        <TeamsList
          teamsListIsShown={teamsListIsShown}
          admin={admin}
          teams={teams}
          changeTeamsSelection={this.changeTeamsSelectionHandler}
          changeAllTeamsSelection={this.changeAllTeamsSelectionHandler}
          openTeamAdmin={this.openTeamAdminHandler}
          closeTeamsList={this.closeTeamsListHandler}
        />
      </div>
    );
  }
}

export default withTranslation()(App);