export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiY2FydG9tZXRyaWNzIiwiYSI6ImNqOGJ2ZXIzazAxd3kyd3AyMDVrOGpzNWkifQ.KwvwFfoDOeLnjR1gEHO8tg';
export const INITIAL_MAP = {
	style: 'mapbox://styles/cartometrics/cjgz6c58g007i2sti3e4jxprq',
	center: [-4.429971, 36.716775],
	zoom: 13.2,
	attributionControl: false
};
export const NAVIGATION = {
	WELCOME: 'welcome',
	MAP: 'map',
	CONTACT: 'contact',
	FAQ: 'faq',
	AUTH: 'auth',
	EDIT_TEAM: 'editTeam',
	DELETE_TEAM: 'deleteTeam',
	NEW_INITIATIVE: 'newInitiative',
	EDIT_INITIATIVE: 'editInitiative',
	DELETE_INITIATIVE: 'deleteInitiative',
	DELETE_INITIATIVES: 'deleteInitiatives',
	TEAM_ADMIN: 'teamAdmin'
};
export const AUTH_TYPE = {
	SIGNUP: 'signup',
	LOGIN: 'login',
	RESET: 'reset',
	EDIT: 'edit',
	DELETE: 'delete'
};
export const AUTH_STATUS = {
	NOT_AUTHENTICATED: 'notAuthenticated',
	AUTHENTICATING: 'authenticating',
	AUTHENTICATED: 'authenticated'
};
export const DB_LOAD_STATUS = {
	LOADING: 'loading',
	SUCCESS: 'success'
};
export const SIGNUP_SERVER_ERROR_TYPES = {
	VALIDATION: 'validation',
	COLOR: 'color',
	EMAIL_EXISTS: 'emailExists',
	PHONE_EXISTS: 'phoneExists',
	TEAM_NAME: 'teamName',
	INTERNAL: 'internal'
};
export const NEW_INITIATIVE_DEFAULT_COLOR = '%2300aec7';
export const MAX_IMAGE_FILE_SIZE_BYTES = 10485760;  // 10MB
export const INITIATIVE_STATUS = {
	SENDING: 'sending',
	SUCCESS: 'success',
	ERROR: 'error'
};
export const CHANGE_MAP = {
	PIN_INITIATIVE: 'pinInitiative',
	CANCEL_INITIATIVE: 'cancelInitiative',
	LOAD_INITIATIVES: 'loadInitiatives',
	ADD_INITIATIVE: 'addInitiative',
	COMPLETE_INITIATIVE: 'completeInitiative',
	DELETE_INITIATIVE: 'deleteInitiative',
	UPDATE_FILTERS: 'updateFilters'
};
export const NOTIFICATION_TYPE = {
	LOGIN: 'login',
	ADMIN_LOGIN: 'adminLogin',
	SUCCESS: 'success',
	ERROR: 'error'
}
export const NOTIFICATION_DURATION = 4000; // ms
export const NUM_QUESTIONS_FAQ = 7;