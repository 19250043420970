import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { firebaseRef, functions, auth, db } from '../../config/firebase';
import { AUTH_TYPE, AUTH_STATUS, DB_LOAD_STATUS, SIGNUP_SERVER_ERROR_TYPES, NOTIFICATION_TYPE } from '../../config';
import AuthForm from './AuthForm';

class Auth extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
			authType: this.props.editTeam ? AUTH_TYPE.EDIT : this.props.deleteTeam ? AUTH_TYPE.DELETE : AUTH_TYPE.LOGIN,
			formData: this.props.editTeam ? null : this.resetFormData(null, null),
			colors: null,
			dbLoadStatus: DB_LOAD_STATUS.LOADING,
			isSending: false,
			authErrorMessage: null,
			dataUpdated: false
	  };
	}

	componentDidMount() {
		if(this.props.editTeam) {
			this.loadContactsFromDB();
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.authStatus === AUTH_STATUS.AUTHENTICATED) {
			this.props.closePageModal();
		}
		if((this.props.editTeam && !prevProps.editTeam) || (this.props.deleteTeam && !prevProps.deleteTeam)) {
			this.props.editTeam ? this.changeAuthTypeHandler(AUTH_TYPE.EDIT) : this.changeAuthTypeHandler(AUTH_TYPE.DELETE);
		}
	}

	resetFormData(colorValue, contactData) {
		const { editTeam, teamData } = this.props;
		return {
			email: {
				value: (editTeam && contactData) ? contactData.email : '',
				validation: {
					required: true,
					email: true
				},
				valid: true,
				errorMessage: null
			},
			password: {
				value: '',
				validation: {
					required: true,
					minLength: 6
				},
				valid: true,
				errorMessage: null
			},
			name: {
				value: (editTeam && teamData) ? teamData.name : '',
				validation: {
					required: true
				},
				valid: true,
				errorMessage: null
			},
			phone: {
				value: (editTeam && contactData) ? contactData.phone : '',
				validation: {
					required: true,
					phone: true
				},
				valid: true,
				errorMessage: null
			},
			members: {
				value: (editTeam && teamData && teamData.members.length > 0) ? teamData.members.map((member, id) => ({ id, member })) : [{ id: 0, member: '' }],
				nextId: (editTeam && teamData && teamData.members.length > 0) ? teamData.members.length + 1 : 1,
				validation: {},
				valid: true
			},
			color: {
				value: colorValue,
				validation: {},
				valid: true
			},
			accept: {
				value: false,
				validation: {
					checkbox: true
				},
				valid: true,
				errorMessage: null
			}
		};
	}

	loadColorsFromDB() {
    db.collection('colors')
      .where('available', '==', true)
      .orderBy(firebaseRef.firestore.FieldPath.documentId())
      .limit(3)
      .get()
	      .then(querySnapshot => {
	      	const colors = [];
	        querySnapshot.forEach(doc => {
	        	colors.push(doc.data().color);
	        });
					this.setState({ colors, formData: this.resetFormData(colors[0], null), dbLoadStatus: DB_LOAD_STATUS.SUCCESS });
	      })
	      .catch(error => {
					this.props.handleAuthDBError(this.props.t('auth.loadingColorsError'));
	      });
	}

	loadContactsFromDB() {
    db.collection('contacts').doc(auth.currentUser.uid).get()
			.then(doc => {
				if(doc.exists) {
					this.setState({ formData: this.resetFormData(null, doc.data()), dbLoadStatus: DB_LOAD_STATUS.SUCCESS });
				} else {
					this.props.handleAuthDBError(this.props.t('auth.loadingContactsError'));
				}
			})
			.catch(error => {
				this.props.handleAuthDBError(this.props.t('auth.loadingContactsError'));
			});
	}

	changeAuthTypeHandler = (authType) => {
		if(authType === AUTH_TYPE.SIGNUP) {
			this.setState({ authType });
			this.loadColorsFromDB();
		}
		else if(authType === AUTH_TYPE.EDIT) {
			this.setState({ authType, dbLoadStatus: DB_LOAD_STATUS.LOADING });
			this.loadContactsFromDB();
		}
		else {
			this.setState({ authType, formData: this.resetFormData(null, null) });
		}
	}

	changeFormDataValue(inputName, value) {
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		updatedFormInput.value = value;
		updatedFormData[inputName] = updatedFormInput;
 		if(this.props.editTeam && !this.state.dataUpdated && inputName !== 'password') {
			this.setState({ formData: updatedFormData, dataUpdated: true });		
		} else {
  		this.setState({ formData: updatedFormData });		
		}
	}

	changeFormDataValidity(inputName, valid, errorMessage) {
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		updatedFormInput.valid = valid;
		updatedFormInput.errorMessage = errorMessage;
		updatedFormData[inputName] = updatedFormInput;
  	this.setState({ formData: updatedFormData });
	}

	changeFormDataHandler = e => {
		const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value;
		this.changeFormDataValue(e.target.name, value);
	}

	changeColorHandler = (color) => {
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData.color };
		updatedFormInput.value = color;
		updatedFormInput.valid = this.checkValidity('color', updatedFormInput.value, updatedFormInput.validation);
		updatedFormData.color = updatedFormInput;
  	this.setState({ formData: updatedFormData });
	}

	changeMembersHandler = (memberId, e) => {
		const value = e.target.value;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData.members };
		updatedFormInput.value = updatedFormInput.value.map(member => {
			if(member.id === memberId) {
				return { id: memberId, member: value };
			} else {
				return member;
			}
		});
		updatedFormData.members = updatedFormInput;
 		if(this.props.editTeam && !this.state.dataUpdated) {
			this.setState({ formData: updatedFormData, dataUpdated: true });		
		} else {
  		this.setState({ formData: updatedFormData });		
		}
	}

	addMemberHandler = e => {
		this.setState(state => {
			const updatedFormData = { ...state.formData };
			const updatedFormInput = { ...updatedFormData.members };
			updatedFormInput.value = [ ...updatedFormInput.value, { id: updatedFormInput.nextId, member: '' } ];
			updatedFormInput.nextId = updatedFormInput.nextId + 1;
			updatedFormData.members = updatedFormInput;
	 		if(this.props.editTeam && !this.state.dataUpdated) {
				return { formData: updatedFormData, dataUpdated: true };	
			} else {
	  		return { formData: updatedFormData };	
			}
		});
	}

	deleteMemberHandler = (memberId, e) => {
		e.preventDefault();
		if(this.state.formData.members.value.length > 1) {
			const updatedFormData = { ...this.state.formData };
			const updatedFormInput = { ...updatedFormData.members };
			updatedFormInput.value = updatedFormInput.value.filter(member => member.id !== memberId);
			updatedFormData.members = updatedFormInput;
	 		if(this.props.editTeam && !this.state.dataUpdated) {
				this.setState({ formData: updatedFormData, dataUpdated: true });		
			} else {
	  		this.setState({ formData: updatedFormData });		
			}
		}
	}

	checkValidity(inputName, value, rules) {
		const { authType } = this.state;
		if(rules.required && value.trim() === '') {
			let errorMessage = this.props.t('auth.validationErrors.required');
			if(authType === AUTH_TYPE.LOGIN) {
				if(inputName === 'email') errorMessage = this.props.t('auth.validationErrors.emailRequiredLogin');
				if(inputName === 'password') errorMessage = this.props.t('auth.validationErrors.passwordRequiredLogin');
			}
			else if(authType === AUTH_TYPE.RESET) {
				if(inputName === 'email') errorMessage = this.props.t('auth.validationErrors.emailRequiredReset');
			}
			return { valid: false, errorMessage };
		}
		if(rules.minLength && value.length < rules.minLength) {
			const errorMessage = (authType === AUTH_TYPE.SIGNUP) 
				? this.props.t('auth.validationErrors.minLength')
				: this.props.t('auth.loginServerErrors.password')
			return { valid: false, errorMessage };
		}
		if(rules.email) {
			const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
			if(!emailRegex.test(value)) {
				return { valid: false, errorMessage: this.props.t('auth.validationErrors.email') };
			}
		}
		if(rules.phone) {
			const phoneRegex = /^(\+34|0034|34)?[6|7|8|9][0-9]{8}$/;
			if(!phoneRegex.test(value)) {
				return { valid: false, errorMessage: this.props.t('auth.validationErrors.phone') };
			}
		}
		if(rules.checkbox && value !== true) {
			return { valid: false, errorMessage: this.props.t('auth.validationErrors.checkbox') };
		}
		return { valid: true, errorMessage: null };
	}

	changeFormDataValidityHandler = e => {
		const inputName = e.target.name;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		if(inputName === 'phone') {
			updatedFormInput.value = updatedFormInput.value.replace(/ /g,'');
		}
		if(inputName === 'email' || inputName === 'name') {
			updatedFormInput.value = updatedFormInput.value.trim();
		}
		if(inputName === 'members') {
			updatedFormInput.value = updatedFormInput.value.map(member => (
				{ id: member.id, member: member.member.trim() }
			));
		}
		const { valid, errorMessage } = this.checkValidity(inputName, updatedFormInput.value, updatedFormInput.validation);
		updatedFormInput.valid = valid;
		updatedFormInput.errorMessage = errorMessage;
		updatedFormData[inputName] = updatedFormInput;
  	this.setState({ formData: updatedFormData });
	}

	checkFullFormValidity() {
		let isFormValid = true;
		const { authType } = this.state;		
		const updatedFormData = { ...this.state.formData };		
		const inputNamesToValidate = (authType === AUTH_TYPE.SIGNUP)
			? Object.keys(updatedFormData)
			: (authType === AUTH_TYPE.LOGIN)
				? ['email', 'password']
				: (authType === AUTH_TYPE.EDIT)
					? ['email', 'password', 'name', 'phone', 'members']
					: ['email'];

		inputNamesToValidate.forEach(inputName => {
			const updatedFormInput = { ...updatedFormData[inputName] };
			if(inputName === 'phone') {
				updatedFormInput.value = updatedFormInput.value.replace(/ /g,'');
			}
			if(inputName === 'email' || inputName === 'name') {
				updatedFormInput.value = updatedFormInput.value.trim();
			}
			if(inputName === 'members') {
				updatedFormInput.value = updatedFormInput.value.map(member => (
					{ id: member.id, member: member.member.trim() }
				));
			}
			const { valid, errorMessage } = this.checkValidity(inputName, updatedFormInput.value, updatedFormInput.validation);
			if(!valid) isFormValid = false;
			updatedFormInput.valid = valid;
			updatedFormInput.errorMessage = errorMessage;
			updatedFormData[inputName] = updatedFormInput;			
		});
		this.setState({ formData: updatedFormData, authErrorMessage: null });
		return isFormValid;
	}

	displaySignupServerErrors(error) {
		switch(error.errorType) {
			case SIGNUP_SERVER_ERROR_TYPES.VALIDATION:
				this.setState({ authErrorMessage: this.props.t('auth.signupServerErrors.validation') });
				break;
			case SIGNUP_SERVER_ERROR_TYPES.EMAIL_EXISTS:
				this.changeFormDataValidity('email', false, this.props.t('auth.signupServerErrors.emailExists'));
		  	break;
		  case SIGNUP_SERVER_ERROR_TYPES.PHONE_EXISTS:
				this.changeFormDataValidity('phone', false, this.props.t('auth.signupServerErrors.phoneExists'));
		  	break;
			case SIGNUP_SERVER_ERROR_TYPES.COLOR:
				this.changeFormDataValidity('color', false, this.props.t('auth.signupServerErrors.color'));
		  	break;
			case SIGNUP_SERVER_ERROR_TYPES.TEAM_NAME:
				this.changeFormDataValidity('name', false, this.props.t('auth.signupServerErrors.teamName'));
		  	break;			  	 	
			case SIGNUP_SERVER_ERROR_TYPES.INTERNAL:
				this.setState({ authErrorMessage: this.props.t('auth.signupServerErrors.internal') });
				break;
		  default:
		  	this.setState({ authErrorMessage: this.props.t('auth.signupServerErrors.internal') });
		  	break;
		}
	}

	displayLoginServerErrors(errorCode) {
		const login = (this.state.authType === AUTH_TYPE.LOGIN);
		switch(errorCode) {
			case 'auth/invalid-email':
			case 'auth/user-not-found':
				this.changeFormDataValidity('email', false, this.props.t('auth.loginServerErrors.email'));
				break;
			case 'auth/wrong-password':
				this.changeFormDataValidity('password', false, this.props.t('auth.loginServerErrors.password'));
				break;
			default:
				const authErrorMessage = (login) ? this.props.t('auth.loginServerErrors.internal') : this.props.t('auth.resetServerErrors.internal');
				this.setState({ authErrorMessage });
				break;
		}
	}

	displayEditServerErrors(error) {
		if(error.code && error.code === 'auth/wrong-password') {
			this.changeFormDataValidity('password', false, this.props.t('auth.loginServerErrors.password'));
		}
		else if(error.details && error.details.errorType) {
			switch(error.details.errorType) {
				case SIGNUP_SERVER_ERROR_TYPES.VALIDATION:
					this.setState({ authErrorMessage: this.props.t('auth.signupServerErrors.validation') });
					break;
				case SIGNUP_SERVER_ERROR_TYPES.EMAIL_EXISTS:
					this.changeFormDataValidity('email', false, this.props.t('auth.signupServerErrors.emailExists'));
			  	break;
			  case SIGNUP_SERVER_ERROR_TYPES.PHONE_EXISTS:
					this.changeFormDataValidity('phone', false, this.props.t('auth.signupServerErrors.phoneExists'));
			  	break;
				case SIGNUP_SERVER_ERROR_TYPES.TEAM_NAME:
					this.changeFormDataValidity('name', false, this.props.t('auth.signupServerErrors.teamName'));
			  	break;			  	 	
			  default:
			  	this.setState({ authErrorMessage: this.props.t('auth.editServerErrors.internal') });
			  	break;
			}
		}
		else {
			this.setState({ authErrorMessage: this.props.t('auth.editServerErrors.internal') });
		}
	}

	displayDeleteServerErrors(error) {
		if(error.code && error.code === 'auth/wrong-password') {
			this.changeFormDataValidity('password', false, this.props.t('auth.loginServerErrors.password'));
		}
		else {
			this.setState({ authErrorMessage: this.props.t('auth.editServerErrors.internal') });
		}
	}

	resetPassword() {
		/* Use functional setState to avoid overriding changes to formData in the last
		validation check (e.g. removing error messages) when merging this formData with
		the password value reset into the React state object */
		this.setState(state => {
			const updatedFormData = { ...state.formData };
			const updatedFormInput = { ...updatedFormData.password };
			updatedFormInput.value = '';
			updatedFormData.password = updatedFormInput;
			return { formData: updatedFormData };
		});
	}

	submitFormDataHandler = e => {
		e.preventDefault();
		if(this.props.authStatus === AUTH_STATUS.AUTHENTICATING || this.state.isSending) return;
		if(!this.checkFullFormValidity()) return;
		const { authType, formData } = this.state;
		if(authType === AUTH_TYPE.SIGNUP) {
			this.props.changeAuthStatus(AUTH_STATUS.AUTHENTICATING);
			const teamData = {};
			for (let inputName in formData) {
				if(inputName === 'members') {
					teamData[inputName] = formData[inputName].value.map(member => member.member).filter(member => member !== '');
				}	else {
					teamData[inputName] = formData[inputName].value;
				}
			}
			this.resetPassword();
			const registerTeam = functions.httpsCallable('registerTeam');
			registerTeam(teamData)
				.then(res => {
					this.props.addNewTeam(res.data);
					auth.signInWithEmailAndPassword(teamData.email, teamData.password)
						.catch(error => {
							this.props.changeAuthStatus(AUTH_STATUS.NOT_AUTHENTICATED);
							this.props.showNotification({ type: NOTIFICATION_TYPE.ERROR, message: this.props.t('auth.loginServerErrors.loginAfterSignup') });
							this.props.closePageModal();
						});
				})
				.catch(error => {
					this.props.changeAuthStatus(AUTH_STATUS.NOT_AUTHENTICATED);
					this.displaySignupServerErrors(error.details);
				});
		} 
		else if(authType === AUTH_TYPE.LOGIN) {
			const email = formData.email.value;
			const password = formData.password.value;
			this.resetPassword();
			this.props.changeAuthStatus(AUTH_STATUS.AUTHENTICATING);
			auth.signInWithEmailAndPassword(email, password)
				.catch(error => {
					this.props.changeAuthStatus(AUTH_STATUS.NOT_AUTHENTICATED);
					this.displayLoginServerErrors(error.code);
				});
		}
		else if(authType === AUTH_TYPE.EDIT) {
			this.setState({ isSending: true });
			const updatedTeamData = {};
			updatedTeamData.email = formData.email.value;
			updatedTeamData.name = formData.name.value;
			updatedTeamData.phone = formData.phone.value;
			updatedTeamData.members = formData.members.value.map(member => member.member).filter(member => member !== '');
			updatedTeamData.password = formData.password.value;
			let updatedTeamDataInDB, updatedTeamInitiativesIds;
			this.resetPassword();
			const credential = firebaseRef.auth.EmailAuthProvider.credential(auth.currentUser.email, updatedTeamData.password);
			auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
				.then(() => {
					const updateTeam = functions.httpsCallable('updateTeam');
					return updateTeam(updatedTeamData);
				})
				.then(res => {
					updatedTeamDataInDB = res.data.updatedTeamData;
					updatedTeamInitiativesIds = res.data.teamInitiativesIds;
					if(res.data.isEmailUpdated) {
						const newCredential = firebaseRef.auth.EmailAuthProvider.credential(updatedTeamData.email, updatedTeamData.password);
						return auth.currentUser.reauthenticateAndRetrieveDataWithCredential(newCredential);
					} else {
						return true;
					}
				})
				.then(() => {
					this.props.updateTeamInApp(updatedTeamDataInDB, updatedTeamInitiativesIds);
				})
				.catch(error => {
					this.setState({ isSending: false });
					this.displayEditServerErrors(error);
				})
		}
		else if(authType === AUTH_TYPE.RESET) {
			this.setState({ isSending: true });
	    auth.sendPasswordResetEmail(formData.email.value)
	      .then(() => {
	        this.props.showNotification({ type: NOTIFICATION_TYPE.SUCCESS, message: this.props.t('auth.resetSuccess') });
	        this.props.closePageModal();
	      })
	      .catch(error => {
	      	this.setState({ isSending: false });
	      	this.displayLoginServerErrors(error.code);
	      });
		}
		else {
			return;
		}
	}

	deleteTeamHandler = e => {
		e.preventDefault();
		this.setState({ isSending: true });
		const password = this.state.formData.password.value;
		this.resetPassword();
		const credential = firebaseRef.auth.EmailAuthProvider.credential(auth.currentUser.email, password);
		auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
			.then(() => {
				const deleteTeam = functions.httpsCallable('deleteTeam');
				return this.props.admin ? deleteTeam(this.props.selectedTeamAdminId) : deleteTeam();
			})
			.then(res => {
				this.props.deleteTeamInApp(res.data);
			})
			.catch(error => {
				console.log(error);
				this.setState({ isSending: false });
				this.displayDeleteServerErrors(error);					
			});
	}

	render() {
		const { authType, formData, colors, dbLoadStatus, isSending, authErrorMessage, dataUpdated } = this.state;
		const { authStatus, closePageModal } = this.props;
		return(
			<AuthForm
				authStatus={authStatus}
				authType={authType}
				formData={formData}
				colors={colors}
				dbLoadStatus={dbLoadStatus}
				isSending={isSending}
				authErrorMessage={authErrorMessage}
				dataUpdated={dataUpdated}
				changeAuthType={this.changeAuthTypeHandler}
				changeFormData={this.changeFormDataHandler}
				changeColor={this.changeColorHandler}
				changeMembers={this.changeMembersHandler}
				addMember={this.addMemberHandler}
				deleteMember={this.deleteMemberHandler}
				changeFormDataValidity={this.changeFormDataValidityHandler}
				submitFormData={this.submitFormDataHandler}
				deleteTeam={this.deleteTeamHandler}
				closePageModal={closePageModal}
			/>
		);
	}
}

export default withTranslation()(Auth);