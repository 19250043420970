import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Welcome.module.scss';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
import logoVicerrectorado from '../../assets/logos/vicerrectorado.png';
import logoAndTech from '../../assets/logos/and-tech.gif';
import logoRedCatedras from '../../assets/logos/red-catedras.png';
import logoInteractividad from '../../assets/logos/interactividad.png';
import logoGeotecnologia from '../../assets/logos/geotecnologia.png';
import logoAyuntamiento from '../../assets/logos/ayuntamiento.png';
import logoPromalaga from '../../assets/logos/promalaga.png';
import logoPolo from '../../assets/logos/polo.png';
import logoCartometrics from '../../assets/logos/cartometrics.png';

const WELCOME_VIDEO_LINK = 'https://www.youtube.com/embed/O095I9xgggk';

const Welcome = ({ navigateToContact, t }) => {
	return(
		<React.Fragment>
			<h1 className={classes.header}>
				<span className={classes.headerDark}>{t('welcome.header1')}</span>
				<span className={classes.headerLight}>{t('welcome.header2')}</span>
			</h1>
			<p className={classes.paragraph1}>{t('welcome.p1')}</p>
			<p className={classes.paragraph2}>{t('welcome.p2')}</p>
			<div className={classes.iframeContainer}>
				<iframe
					src={WELCOME_VIDEO_LINK}
					className={classes.iframe}
					title='video'
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen>
				</iframe>
			</div>
			<h2 className={classes.socialMediaHeader}>{t('welcome.socialMediaHeader')}</h2>
			<div className={classes.socialMediaIcons}>
				<EmailIcon
					className={`${classes.socialMediaIcon} ${classes.email}`}
					onClick={navigateToContact}
				/>
				<a
					className={`${classes.socialMediaIcon} ${classes.twitter}`}
					href='https://twitter.com/malagaluce'
					target='_blank'
					rel='noopener noreferrer'
				>
					<TwitterIcon className={classes.twitter}/>
				</a>
				<FacebookIcon className={`${classes.socialMediaIcon} ${classes.facebook}`}/>
				<a
					className={`${classes.socialMediaIcon} ${classes.instagram}`}
					href='https://www.instagram.com/malagaluce'
					target='_blank'
					rel='noopener noreferrer'
				>
					<InstagramIcon className={classes.instagram}/>
				</a>
			</div>
			<div className={classes.logosRow}>
				<img src={logoVicerrectorado} className={classes.logoVicerrectorado} alt='vicerrectorado' />
				<img src={logoAndTech} className={classes.logoAndTech} alt='and-tech' />
				<img src={logoRedCatedras} className={classes.logoRedCatedras} alt='red-catedras' />
			</div>
			<div className={`${classes.logosRow} ${classes.second}`}>
				<img src={logoInteractividad} className={classes.logoInteractividad} alt='interactividad' />
				<img src={logoGeotecnologia} className={classes.logoGeotecnologia} alt='geotecnologia' />
			</div>
			<div className={`${classes.logosRow} ${classes.third}`}>
				<img src={logoAyuntamiento} className={classes.logoAyuntamiento} alt='red-catedras' />
				<img src={logoPromalaga} className={classes.logoPromalaga} alt='logo' />
				<img src={logoPolo} className={classes.logoPolo} alt='red-catedras' />
				<img src={logoCartometrics} className={classes.logoCartometrics} alt='logo' />
			</div>
		</React.Fragment>
	);
}

export default withTranslation()(Welcome);