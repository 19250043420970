import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './CreateEditInitiativeForm.module.scss';
import { INITIATIVE_STATUS } from '../../config';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';

const CreateEditInitiativeForm = (props) => {
	const {
		isNew,
		formData,
		imagesData,
		dataUpdated,
		teamColor,
		initiativeStatus,
		message,
		changeFormData,
		changeFormDataValidity,
		selectImage,
		submitFormData,
		t
	} = props;
	const imagesInputRef = { before: React.createRef(), after: React.createRef() };

	const imageInputKeyPressHandler = (imageName, e) => {
		if(e.keyCode === 13 || e.keyCode === 32) {
			e.preventDefault();
			imagesInputRef[imageName].current.click();
		}
	}

	const statusSending = initiativeStatus === INITIATIVE_STATUS.SENDING;
	const statusError = initiativeStatus === INITIATIVE_STATUS.ERROR;

	const header = (
		<React.Fragment>
			<div className={classes.header}>
				{teamColor && (
					<div className={classes.headerCircle} style={{ backgroundColor: teamColor }}></div>
				)}
				<h1 className={classes.headerText}>
					{isNew ? t('createEditInitiative.headerCreate') : t('createEditInitiative.headerEdit')}
				</h1>
			</div>
			{statusError && (
				<p className={classes.errorMessage}>{message}</p>
			)}
		</React.Fragment>
	);

	const images = {};
	['before', 'after'].forEach(imageName => {
		const imageUrl = imagesData[imageName].previewUrl;
		images[imageName] = imageUrl ? (
			<div
				className={classes.imageContainer}
				tabIndex='0'
				onClick={() => imagesInputRef[imageName].current.click()}
				onKeyDown={imageInputKeyPressHandler.bind(this, imageName)}
				style={{ backgroundImage: `url(${imageUrl})` }}
			>
				<ImageIcon className={classes.miniImageIcon}/>
				<input 
					type='file'
					name={imageName}
					accept='.jpg, .jpeg, .png'
					style={{ display: 'none' }} 
					onChange={selectImage}
					ref={imagesInputRef[imageName]}
				/>			
			</div>
		) : (
			<React.Fragment>
				<div
					className={classes.iconContainer}
					tabIndex='0'
					onClick={() => imagesInputRef[imageName].current.click()}
					onKeyDown={imageInputKeyPressHandler.bind(this, imageName)}
				>
					<ImageIcon className={classes.imageIcon}/>
					<input 
						type='file'
						name={imageName}
						accept='.jpg, .jpeg, .png'
						style={{ display: 'none' }} 
						onChange={selectImage}
						ref={imagesInputRef[imageName]}
					/>
				</div>
			</React.Fragment>
		);
	});

	const submitButtonText = statusSending ? null
		: isNew ? t('createEditInitiative.sendButton')
		: t('createEditInitiative.updateButton');
		
	const form = (
		<form onSubmit={submitFormData}>
			<div className={classes.formFields}>
				<div className={`field ${classes.field}`}>
					<div className='control'>
						<input 
							className={`input ${classes.input} ${formData.name.valid ? '' : classes.invalid}`}
							name='name' 
							type='text'
							placeholder={t('createEditInitiative.name')}
							value={formData.name.value}
							onChange={changeFormData}
							onBlur={changeFormDataValidity}
						/>
					</div>
					{!formData.name.valid && (
						<p className={classes.inputErrorMessage}>{formData.name.errorMessage}</p>
					)}
				</div>
				<div className={`field ${classes.field} ${classes.imagesField}`}>
					<p className={classes.imagesText}>
						{isNew ? t('createEditInitiative.imagesTextCreate')
							: !imagesData.after.previewUrl ? t('createEditInitiative.imagesTextEdit') : null}
					</p>
					<div className={classes.images}>
						<div className={classes.column}>
							{images.before}
							<span className={classes.imageCaption}>{t('initiative.before')}</span>
						</div>
						<div className={classes.column}>
							{images.after}
							<span className={classes.imageCaption}>{t('initiative.after')}</span>
						</div>
					</div>
					{!imagesData.valid && (
						<p className={classes.imagesErrorMessage}>{imagesData.errorMessage}</p>
					)}
				</div>
				<div className={`field ${classes.field}`}>
					<div className='control'>
						<textarea 
							className={`input ${classes.input} ${classes.textarea}`}
							name='description' 
							type='text'
							placeholder={t('createEditInitiative.description')}
							value={formData.description.value}
							onChange={changeFormData}
							onBlur={changeFormDataValidity}
						/>
					</div>
				</div>								
			</div>
			<button 
				className={`button ${classes.submitButton} ${statusSending ? 'is-loading' : ''}`}
				disabled={!isNew && !dataUpdated}
				onClick={!statusSending ? submitFormData : null}
			>
				{submitButtonText}
			</button>
		</form>
	);

	return (
		<React.Fragment>
			{header}
			{form}
		</React.Fragment>
	);
}

export default withTranslation()(CreateEditInitiativeForm);