import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { auth } from '../../config/firebase';
import classes from './InitiativePopup.module.scss';
import { ReactComponent as ImageIcon } from '../../assets/icons/image.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter-official.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-official.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';

class InitiativePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullScreenImage: { show: false, image: null },
      linkCopied: false
    };
    this.linkTextRef = React.createRef();
  }

  shareOnFacebook = () => {
  	window.FB.ui({
		  method: 'share',
		  href: 'https://malaga-luce.firebaseapp.com?id=' + this.props.selectedInitiativeId,
		  hashtag: '#malagaluce'
		}, function(response){});
  }

  copyInitiativeLink = e => {
  	e.preventDefault();
  	this.linkTextRef.current.select();
  	this.linkTextRef.current.blur();
  	document.execCommand('copy');
  	this.setState({ linkCopied: true });
  	window.setTimeout(() => this.setState({ linkCopied: false }), 500);
  }

	render() {
		const { show, isAuthenticated, admin, initiativeData, editInitiative, deleteInitiative, closePopup, t } = this.props;
		const { fullScreenImage, linkCopied } = this.state;

		let initiativeContent = null;
		let modal = null;
		if(show) {
			const headerCircleStyle = { backgroundColor: initiativeData.teamColor };
			if(initiativeData.afterImageUrl) {
				headerCircleStyle['boxShadow'] = '0px 2px 2px 0px #555e63, 0px 0px 10px 7px #ffeab0';
			} else {
				headerCircleStyle['boxShadow'] = '0px 2px 6px 0px #555e63';
			}

			const header = (
				<React.Fragment>
					<div className={classes.header}>
						<div>
							<div
								className={classes.headerCircle}
								style={headerCircleStyle}>
							</div>
						</div>
						<h1 className={classes.headerText}>{initiativeData.name}</h1>
					</div>
					<div className={classes.subHeader}>
						<span className={classes.teamLabel}>{t('initiative.team')}</span>
						<span
							className={`${classes.teamName} ${admin ? classes.admin : ''}`}
							onClick={admin ? this.props.openTeamAdmin.bind(this, initiativeData.teamId) : null}
						>
							{initiativeData.teamName}
						</span>
					</div>
				</React.Fragment>
			);

			const beforeImage = (
				<div
					className={classes.imageContainer}
					style={{ backgroundImage: `url(${initiativeData.beforeImageUrl})` }}
					onClick={() => this.setState({ fullScreenImage: { show: true, image: 'before' }})}
				>
				</div>
			);

			const afterImage = initiativeData.afterImageUrl ? (
				<div
					className={classes.imageContainer}
					style={{ backgroundImage: `url(${initiativeData.afterImageUrl})` }}
					onClick={() => this.setState({ fullScreenImage: { show: true, image: 'after' }})}
				>
				</div>
			) : (
				<div className={classes.iconContainer}>
					<ImageIcon className={classes.imageIcon}/>
				</div>
			);

			const images = (
				<div className={classes.imagesRow}>
					<div className={classes.column}>
						{beforeImage}
						<span className={classes.imageCaption}>{t('initiative.before')}</span>
					</div>
					<div className={classes.column}>
						{afterImage}
						<span className={classes.imageCaption}>{t('initiative.after')}</span>
					</div>
				</div>
			);

			const description = (
				<div className={classes.descriptionRow}>
					<span className={classes.label}>{t('initiative.description')}</span>
					<div className={classes.description}>
						{initiativeData.description.map((paragraph, idx) => (
							<p key={idx}>{paragraph}</p>
						))}
					</div>
				</div>	
			);

			const twitterIntentHref = 'https://twitter.com/intent/tweet?'
				+ 'url=' + encodeURIComponent('https://malaga-luce.firebaseapp.com?id=' + this.props.selectedInitiativeId)
				+ '&text=' + encodeURIComponent(t('socialMedia.customText'));

			const membersSocialMedia = (
				<div className={classes.membersAndSocialMediaRow}>
					<div className={`${classes.column} ${classes.membersColumn}`}>
						<span className={classes.label}>{t('initiative.members')}</span>
						<ul className={classes.members}>
							{initiativeData.teamMembers && initiativeData.teamMembers.map((member, idx) => (
								<li key={idx} className={classes.member}>{member}</li>
							))}
						</ul>
					</div>
					<div className={classes.columnWithRows}>
						<div className={classes.socialMedia}>
							<span className={classes.label}>{t('initiative.socialMedia')}</span>
							<div className={classes.socialMediaIcons}>
								<a href={twitterIntentHref} target='_blank' rel='noopener noreferrer'>
									<TwitterIcon className={classes.twitterIcon} />
								</a>
								<FacebookIcon className={classes.facebookIcon} onClick={this.shareOnFacebook}/>
								<div className={classes.linkIconContainer}>
									<LinkIcon
										className={`${classes.linkIcon} ${linkCopied ? classes.copied : ''}`}
										onClick={this.copyInitiativeLink}
									/>
									<div className={classes.linkCopiedMessage}>enlace copiado</div>
								</div>
								<textarea
									className={classes.hiddenLinkText}
									ref={this.linkTextRef}
									readOnly
									value={window.location.origin + '?id=' + this.props.selectedInitiativeId}
								></textarea>
							</div>			
						</div>
						{isAuthenticated && (admin || auth.currentUser.uid === initiativeData.teamId) && (
							<div className={classes.buttonsContainer}>
								<button className={`button ${classes.button}`} onClick={editInitiative}>
									{t('initiative.edit')}
								</button>
								<button className={`button ${classes.button}`} onClick={deleteInitiative}>
									{t('initiative.delete')}
								</button>
							</div>
						)}
					</div>
				</div>
			);

			initiativeContent = (
				<React.Fragment>
					{header}
					{images}
					{description}
					{membersSocialMedia}			
				</React.Fragment>
			);

			if(fullScreenImage.show) {
				modal = (
					<div
						className={`modal ${fullScreenImage.show ? 'is-active' : ''}`}
						onClick={() => this.setState({ fullScreenImage: { show: false, image: null }})}
					>
						<div className={`modal-background ${classes.modalBackground}`}></div>
						<button className={`modal-close is-large ${classes.modalCloseButton}`} aria-label="close"></button>
						<div className={`modal-content ${classes.modalContent}`}>
							<img
								className={classes.fullScreenImage}
								src={fullScreenImage.image === 'before' ? initiativeData.beforeImageUrl : initiativeData.afterImageUrl}
								alt='full-screen'
							/>
						</div>
					</div>
				);
			}
		}

		return(
			<React.Fragment>
				<div className={`${classes.popup} ${show ? classes.show : ''}`}>
					<button 
						className={`delete is-medium ${classes.closeButton}`}
						onClick={closePopup}>
					</button>
					{initiativeContent}
				</div>
				{modal}
			</React.Fragment>
		);
	}
}

export default withTranslation()(InitiativePopup);