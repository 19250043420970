import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './Notification.module.scss';
import { NOTIFICATION_TYPE, NOTIFICATION_DURATION } from '../../config';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

class Notification extends Component {

	timeoutId = null;

	componentDidUpdate(prevProps) {
		if(this.props.notification !== prevProps.notification) {
			if(this.timeoutId) {
				window.clearTimeout(this.timeoutId);
				this.timeoutId = null;
			}
			if(this.props.notification !== null) {
				this.timeoutId = window.setTimeout(this.props.closeNotification, NOTIFICATION_DURATION);
			}
		}
	}

	notificationClickHandler = () => {
		if(this.timeoutId) {
			window.clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		this.props.closeNotification();
	}

	render() {
		let notificationContent = null;
		const { notification } = this.props;
		if(notification) {
	    switch(notification.type) {
	      case NOTIFICATION_TYPE.LOGIN:
	      	notificationContent = (
						<React.Fragment>
							<div className={classes.loginCircleContainer}>
								<div className={classes.loginCircle} style={{ backgroundColor: this.props.teamData.color }}/>
							</div>
							<div className={classes.loginTextContainer}>
								<p className={classes.loginText}>{this.props.t('notification.login') + this.props.teamData.name + '!'}</p>						
							</div>
						</React.Fragment>
	      	);
	        break;
	      case NOTIFICATION_TYPE.ADMIN_LOGIN:
	      	notificationContent = (
						<p className={classes.adminLogin}>{this.props.t('notification.adminLogin')}</p>						
	      	);
	        break;
	      case NOTIFICATION_TYPE.SUCCESS:
	      	notificationContent = (
						<p className={classes.successMessage}>
							<SuccessIcon className={classes.successIcon} />
							{notification.message}
						</p>
	      	);
	        break;
	      case NOTIFICATION_TYPE.ERROR:
	      	notificationContent = (
	      		<React.Fragment>
		      		<ErrorIcon className={classes.errorIcon} />
							<p className={classes.errorMessage}>{notification.message}</p>
						</React.Fragment>
	      	);
	        break;        
	      default:
	        break;
	    }

		}
		return(
			<div
				className={`${classes.notification} ${this.props.notification ? classes.show : ''}`}
				onClick={this.notificationClickHandler}
			>
				{notificationContent}
			</div>
		);
	}
}

export default withTranslation()(Notification);