import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classes from './TeamAdmin.module.scss';
import { db } from '../../config/firebase';
import { DB_LOAD_STATUS } from '../../config';
import Spinner from '../Spinner/Spinner';

class TeamAdmin extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
			dbLoadStatus: DB_LOAD_STATUS.LOADING,
			contactData: null
	  };
	}

	componentDidMount() {
		this.loadContactsFromDB();
	}

	loadContactsFromDB() {
    db.collection('contacts').doc(this.props.teamData.id).get()
			.then(doc => {
				if(doc.exists) {
					this.setState({ contactData: doc.data(), dbLoadStatus: DB_LOAD_STATUS.SUCCESS });
				} else {
					this.props.handleDBLoadError(this.props.t('teamAdmin.loadingContactsError'));
				}
			})
			.catch(error => {
				this.props.handleDBLoadError(this.props.t('teamAdmin.loadingContactsError'));
			});
	}

	render() {
		const { teamData, t } = this.props;
		const { dbLoadStatus, contactData } = this.state;
		// console.log("[Team Admin]:", teamData);

		const displayDate = (timestamp) => {
			const date = new Date(timestamp);
			return date.getDate() + ' ' + t(`months.${date.getMonth()}`) + ' ' + date.getFullYear();
		}

		let teamAdmin = null;
		if(dbLoadStatus === DB_LOAD_STATUS.LOADING) {
			teamAdmin = (
				<div className={classes.loadingBlankPage}>
					<Spinner />
				</div>
			);
		}
		else if(teamData) {
			teamAdmin = (
				<React.Fragment>
					<div className={classes.header}>
						<div className={classes.headerCircle} style={{ backgroundColor: teamData.color }}></div>
						<h1 className={classes.headerText}>{t('teamAdmin.team')}</h1>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.name')}</span>
						<span className={classes.text}>{teamData.name}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.members')}</span>
						<ul className={classes.membersList}>
							{teamData.members.map((member, idx) => (
								<li key={idx} className={classes.member}>{member}</li>
							))}
						</ul>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.creationDate')}</span>
						<span className={classes.text}>{displayDate(teamData.creationDate)}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.email')}</span>
						<span className={classes.text}>{contactData.email}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.phone')}</span>
						<span className={classes.text}>{contactData.phone}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.numCreated')}</span>
						<span className={classes.text}>{teamData.numCreated}</span>
					</div>
					<div className={classes.field}>
						<span className={classes.label}>{t('teamAdmin.numCompleted')}</span>
						<span className={classes.text}>{teamData.numCompleted}</span>
					</div>
					{teamData.lastCreated !== 0 && (
						<div className={classes.field}>
							<span className={classes.label}>{t('teamAdmin.lastCreated')}</span>
							<span className={classes.text}>{displayDate(teamData.lastCreated)}</span>
						</div>
					)}
					{teamData.lastCompleted !== 0 && (
						<div className={classes.field}>
							<span className={classes.label}>{t('teamAdmin.lastCompleted')}</span>
							<span className={classes.text}>{displayDate(teamData.lastCompleted)}</span>
						</div>
					)}
					<button
						className={classes.deleteButton}
						onClick={this.props.confirmDelete}
					>
						{t('teamAdmin.deleteButton')}
					</button>
				</React.Fragment>
			);
		}

		return teamAdmin;

	}
}

export default withTranslation()(TeamAdmin);