import React from 'react';
import classes from './PageModal.module.scss';

const PageModal = ({ show, closePageModal, children }) => {
	let modalClasses = [classes.modal];
	if(show) modalClasses.push(classes.show);
	return(
		<div className={modalClasses.join(' ')}>
			<div className={classes.modalBackground} onClick={closePageModal}></div>
			<div className={classes.modalDialog}>
				<button 
					className={`delete is-medium ${classes.closeButton}`}
					onClick={closePageModal}>
				</button>
				{children}
			</div>
		</div>
	);
}

export default PageModal;