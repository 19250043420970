import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import uuidv4 from 'uuid/v4';
import CreateEditInitiativeForm from './CreateEditInitiativeForm';
import { storage, functions } from '../../config/firebase';
import { MAX_IMAGE_FILE_SIZE_BYTES, INITIATIVE_STATUS } from '../../config';

class CreateEditInitiative extends Component {

	constructor(props) {
	  super(props);
	  this.state = {
			formData: this.setInitialFormData(),
			imagesData: this.setInitialImagesData(),
			dataUpdated: false,
			initiativeStatus: null,
			message: null
	  };
	}

	componentWillUnmount() {
		if(this.props.isNew && this.state.initiativeStatus !== INITIATIVE_STATUS.SUCCESS) {
			this.props.cancelNewInitiative();
		}
		else if(!this.props.isNew && this.state.initiativeStatus !== INITIATIVE_STATUS.SUCCESS) {
			this.props.cancelEditInitiative();
		}
	}

	setInitialFormData() {
		const nameValue = this.props.isNew ? '' : this.props.initiativeData.name;
		const descriptionValue = this.props.isNew ? '' : this.props.initiativeData.description.join('\n');
		return {				
			name: {
				value: nameValue,
				valid: true,
				errorMessage: null
			},
			description: {
				value: descriptionValue,
				valid: true,
				errorMessage: null
			}
		};
	}

	setInitialImagesData() {
		const beforeImageUrl = this.props.isNew ? null : this.props.initiativeData.beforeImageUrl;
		const afterImageUrl = this.props.isNew ? null : this.props.initiativeData.afterImageUrl;
		return {				
				before: {
					file: null,
					previewUrl: beforeImageUrl
				},
				after: {
					file: null,
					previewUrl: afterImageUrl
				},
				valid: true,
				errorMessage:null
		};
	}

	changeFormDataHandler = e => {
		const inputName = e.target.name;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		updatedFormInput.value = e.target.value;
		updatedFormData[inputName] = updatedFormInput;
		if(!this.props.isNew && !this.state.dataUpdated) {
			this.setState({ formData: updatedFormData, dataUpdated: true });		
		} else {
  		this.setState({ formData: updatedFormData });		
		}
	}

	changeFormDataValidityHandler = e => {
		const inputName = e.target.name;
		const updatedFormData = { ...this.state.formData };
		const updatedFormInput = { ...updatedFormData[inputName] };
		updatedFormInput.value = e.target.value.trim();
		if(inputName === 'name') {
			const valid = (updatedFormInput.value !== '');
			updatedFormInput.valid = valid;
			updatedFormInput.errorMessage = valid ? null : this.props.t('createEditInitiative.validationErrors.required');
		}
		updatedFormData[inputName] = updatedFormInput;
  	this.setState({ formData: updatedFormData });		
	}

	selectImageHandler = e => {
		const inputName = e.target.name;
		const file = e.target.files[0];
		const updatedImagesData = { ...this.state.imagesData };
		// Image file type validation
		if(file.type !== 'image/jpeg' && file.type !== 'image/png') {
			updatedImagesData.valid = false;
			updatedImagesData.errorMessage = this.props.t('createEditInitiative.validationErrors.fileType');
		}
		// Image file size validation
		else if(file.size > MAX_IMAGE_FILE_SIZE_BYTES) {
			updatedImagesData.valid = false;
			updatedImagesData.errorMessage = this.props.t('createEditInitiative.validationErrors.fileSize');
		}
		else {
			updatedImagesData.valid = true;
			updatedImagesData.errorMessage = null;
			const updatedImageInput = { ...updatedImagesData[inputName] };
			if(updatedImageInput.previewUrl && updatedImageInput.file) {
				URL.revokeObjectURL(updatedImageInput.previewUrl);
			}
			updatedImageInput.file = file;
			updatedImageInput.previewUrl = URL.createObjectURL(file);
			updatedImagesData[inputName] = updatedImageInput;
			if(!this.props.isNew && !this.state.dataUpdated) {
				this.setState({ imagesData: updatedImagesData, dataUpdated: true });
				return;
			}
		}
  	this.setState({ imagesData: updatedImagesData });
	}

	fullFormValidation() {
		let valid = true;
		// Validate Initiative Name
		const updatedFormData = { ...this.state.formData };
		const updatedNameInput = { ...updatedFormData.name };
		updatedNameInput.value = updatedNameInput.value.trim();
		updatedNameInput.valid = (updatedNameInput.value !== '');
		updatedNameInput.errorMessage = updatedNameInput.valid ? null : this.props.t('createEditInitiative.validationErrors.required');
		if(!updatedNameInput.valid) valid = false;
		updatedFormData.name = updatedNameInput;
		// Validate Image
		const updatedImagesData = { ...this.state.imagesData };
		updatedImagesData.valid = !this.props.isNew || updatedImagesData.before.file !== null;
		updatedImagesData.errorMessage = updatedImagesData.valid ? null : this.props.t('createEditInitiative.validationErrors.imageRequired');
		if(!updatedImagesData.valid) valid = false;
		this.setState({ formData: updatedFormData, imagesData: updatedImagesData });
		return valid;
	}

	submitFormDataHandler = e => {
		e.preventDefault();
		if(!this.fullFormValidation()) return;
		if(this.props.isNew && !this.props.newInitiativeCoordinates) {
			const message = this.props.isNew ? this.props.t('createEditInitiative.serverErrorCreate') : this.props.t('createEditInitiative.serverErrorUpdate');
			this.setState({
				initiativeStatus: INITIATIVE_STATUS.ERROR,
				message
			});
			return;
		}
		this.setState({ initiativeStatus: INITIATIVE_STATUS.SENDING });
		const beforeImageSubmitted = this.state.imagesData.before.file !== null;
		const afterImageSubmitted = this.state.imagesData.after.file !== null;
		let initiativeData = {
			name: this.state.formData.name.value,
			description: this.state.formData.description.value.split('\n'),
			beforeImageUrl: null,
			beforeImageFilename: null,
			afterImageUrl: null,
			afterImageFilename: null,
		};
		if (this.props.isNew) {
			initiativeData.coordinates = this.props.newInitiativeCoordinates;
		} else {
			initiativeData.initiativeId = this.props.selectedInitiativeId;
		}
		const storagePromises = [];
		if(beforeImageSubmitted) {
			initiativeData.beforeImageFilename = 'images/' + uuidv4();
			storagePromises.push(storage.ref().child(initiativeData.beforeImageFilename).put(this.state.imagesData.before.file));
		}
		if(afterImageSubmitted) {
			initiativeData.afterImageFilename = 'images/' + uuidv4();
			storagePromises.push(storage.ref().child(initiativeData.afterImageFilename).put(this.state.imagesData.after.file));
		}
		Promise.all(storagePromises)
			.then(snapshots => {
				return Promise.all(snapshots.map(snapshot => snapshot.ref.getDownloadURL()));
			})
			.then(urls => {
				if(urls.length === 2) {
					initiativeData.beforeImageUrl = urls[0];
					initiativeData.afterImageUrl = urls[1];
				}
				else if(urls.length === 1 && beforeImageSubmitted) {
					initiativeData.beforeImageUrl = urls[0];
				}
				else if(urls.length === 1 && afterImageSubmitted) {
					initiativeData.afterImageUrl = urls[0];
				}
				if(this.props.isNew) {
					const createInitiative = functions.httpsCallable('createInitiative');
					return createInitiative(initiativeData);
				} else {
					const updateInitiative = functions.httpsCallable('updateInitiative');
					return updateInitiative(initiativeData);					
				}
			})
			.then(res => {
				if(this.state.imagesData.before.file) URL.revokeObjectURL(this.state.imagesData.before.previewUrl);
				if(this.state.imagesData.after.file) URL.revokeObjectURL(this.state.imagesData.after.previewUrl);
				this.setState({ initiativeStatus: INITIATIVE_STATUS.SUCCESS });
				if(this.props.isNew) {
					this.props.addNewInitiative(res.data.initiativeId, res.data.initiativeData);
				} else {
					// const imagesUpdated = {
					// 	before: this.state.imagesData.before.file !== null,
					// 	after: this.state.imagesData.after.file !== null
					// };
					this.props.updateInitiative(res.data.updatedInitiativeData);
				}
			})
			.catch(error => {
				console.log(error);
				const message = this.props.isNew ? this.props.t('createEditInitiative.serverErrorCreate') : this.props.t('createEditInitiative.serverErrorUpdate');
				this.setState({
					initiativeStatus: INITIATIVE_STATUS.ERROR,
					message
				});
			});
	}

	render() {
		const { formData, imagesData, dataUpdated, initiativeStatus, message } = this.state;
		const { isNew, teamColor, initiativeData } = this.props;
		return(
			<CreateEditInitiativeForm
				isNew={isNew}
				formData={formData}
				imagesData={imagesData}
				dataUpdated={dataUpdated}
				teamColor={teamColor ? teamColor : initiativeData ? initiativeData.teamColor : null}
				initiativeStatus={initiativeStatus}
				message={message}
				changeFormData={this.changeFormDataHandler}
				changeFormDataValidity={this.changeFormDataValidityHandler}
				selectImage={this.selectImageHandler}
				submitFormData={this.submitFormDataHandler}
			/>
		);
	}
}

export default withTranslation()(CreateEditInitiative);