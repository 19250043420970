import React from 'react';
import { withTranslation } from 'react-i18next';
import classes from './TeamsList.module.scss';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg';

const TeamsList = (props) => {
	const {
		teamsListIsShown,
		admin,
		teams,
		changeTeamsSelection,
		changeAllTeamsSelection,
		openTeamAdmin,
		closeTeamsList,
		t
	} = props;

	let teamsList = null;
	if(teams && teamsListIsShown) {
		teamsList = (
			<ul className={classes.teamsList}>
				{teams.map(team => (
					<li key={team.id} className={`${classes.teamRow} ${team.selected ? classes.checked : ''}`}>
						<div className={classes.checkboxContainer}>
							<input
								type='checkbox'
								name={team.id}
								className={classes.checkbox}
								checked={team.selected}
								onChange={changeTeamsSelection}
							/>
							<CheckmarkIcon className={classes.checkmark} />
						</div>
						<span
							className={`${classes.teamName} ${admin ? classes.admin : ''}`}
							onClick={admin ? openTeamAdmin.bind(this, team.id) : null}
						>
							{team.name}
						</span>
						<div
							className={classes.teamCircle}
							style={{ backgroundColor: team.color }}>
						</div>
					</li>
				))}
				<li className={classes.selectAllNone}>
					<span onClick={changeAllTeamsSelection.bind(this, true)}>{t('teamsList.selectAll')}</span>
					<span onClick={changeAllTeamsSelection.bind(this, false)}>{t('teamsList.selectNone')}</span>
				</li>
			</ul>
		);
	}
	return(
		<div className={`${classes.teamsListContainer} ${teams && teamsListIsShown ? classes.show : ''}`}>
			<button 
				className={`delete ${classes.closeButton}`}
				onClick={closeTeamsList}>
			</button>
			<h1 className={classes.header}>{t('teamsList.teams')}</h1>
			{teamsList}
		</div>
	);
}

export default withTranslation()(TeamsList);